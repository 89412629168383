import React from "react";
import styled from "styled-components";
import { formatTimeToView } from "../../../../../../helpers/formatTime";
export const ChatItem = ({ chat, onClick }) => {
  return (
    <Wrapper onClick={onClick}>
      <WrapperInner className="unread-block">
        {!chat?.is_read && <UnreadBlock />}
      </WrapperInner>
      <WrapperInner>
        <ItemHeading>
          <ItemHeadingInner className="authors">
            <AuthorBlock>
              <p className="chat-author">{`${chat.profile}`}</p>
            </AuthorBlock>
          </ItemHeadingInner>
          <ItemHeadingInner>
            {formatTimeToView(chat.last_message_created_at)}
          </ItemHeadingInner>
        </ItemHeading>
        <ItemBody>{chat.last_message_text || "{no messages yet}"}</ItemBody>
      </WrapperInner>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;

  padding: 16px;
  cursor: pointer;
  border-bottom: 1px solid rgba(228, 231, 236, 1);

  & .unread-block {
    display: flex;
    align-items: center;
  }
`;

const WrapperInner = styled.div`
  /* display: flex;
  align-items: center; */
`;

const ItemHeading = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & .authors {
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;
const ItemHeadingInner = styled.div`
  gap: 20px;
`;
const ItemBody = styled.p`
  font-family: "Inter";
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  text-align: left;
  color: #667085;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: normal;
  word-break: break-all;
`;

const AuthorBlock = styled.div`
  & .chat-author {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;
    text-align: left;
    margin-bottom: 0;
  }

  & .chat-tag {
    font-family: "Inter";
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    text-align: left;
    margin-bottom: 0;
  }
`;

const UnreadBlock = styled.div`
  width: 8px;
  height: 8px;
  gap: 0px;
  border-radius: 50%;
  opacity: 0px;
  background: #f27232;
`;
