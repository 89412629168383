import { Spinner } from "react-bootstrap";
import * as media from "../../../../crud/media.crud";
import React, { useState, useEffect } from "react";
import styled from "styled-components";

export const ImgComponent = ({
  src,
  alt,
  className,
  style,
  onError = () => null,
  withIndicator = false,
}) => {
  const [url, setUrl] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (src) {
      setLoading(true);
      media
        .getImg(src)
        .then((res) => setUrl(res))
        .catch((err) => {
          onError();
        })
        .finally(() => setLoading(false));
    }
  }, [src]);

  if (loading && withIndicator) {
    return (
      <SpinnerWrapper className={className} style={style}>
        <Spinner animation="border" size="sm" role="status">
          <span className="sr-only">Loading...</span>
        </Spinner>
      </SpinnerWrapper>
    );
  }

  return url && <img src={url} alt={alt} className={className} style={style} />;
};

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
