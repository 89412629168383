import React, { useState } from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  CHAT_VIEW_TYPE_TITLE,
  CHAT_VIEW_TYPE,
  VIEW_MESSAGING_TYPE,
} from "../../../../constants";

import { Search, Clear, MoreVert } from "@material-ui/icons";
import {
  SettingChatIcon,
  LeftIcon,
  ArchiveIcon,
  LogoutIcon,
} from "../../../../../../../../assets";
import {
  setChatViewTypeAction,
  setChatIdAction,
  setViewTypeAction,
} from "../../../../../../../store/actions";
import { ImgComponent } from "../../../../../Sales/helpers/ImgComponent";
import { ChatAvatars } from "../../../Chats/components/ChatsList/components/ChatAvatars/ChatAvatars";

const CustomToggle = React.forwardRef(({ children, onClick, ...rest }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
    }}
    {...rest}
  >
    {children}
  </div>
));

const items = [
  {
    title: CHAT_VIEW_TYPE_TITLE[CHAT_VIEW_TYPE.SETTINGS],
    value: VIEW_MESSAGING_TYPE.SETTINGS,
    icon: SettingChatIcon,
  },
  {
    title: CHAT_VIEW_TYPE_TITLE[CHAT_VIEW_TYPE.HIDE_CHAT],
    value: CHAT_VIEW_TYPE.HIDE_CHAT,
    icon: ArchiveIcon,
  },
  {
    title: CHAT_VIEW_TYPE_TITLE[CHAT_VIEW_TYPE.LEAVE_CHAT],
    value: CHAT_VIEW_TYPE.LEAVE_CHAT,
    icon: LogoutIcon,
  },
];

export const MessagesHeader = ({ currentChatId }) => {
  const dispatch = useDispatch();

  const { profile_obj, profile } = useSelector((state) =>
    state.chat.chats.chatList.find((item) => item.id === currentChatId)
  );

  const handleSelect = (type) => {
    dispatch(setChatViewTypeAction(+type));
    if ([VIEW_MESSAGING_TYPE.SETTINGS].includes(+type)) {
      dispatch(setViewTypeAction(+type));
    }
  };

  const handleBack = () => {
    // dispatch(setChatIdAction(null));
    dispatch(setViewTypeAction(VIEW_MESSAGING_TYPE.CHATS));
  };

  return (
    <Wrapper>
      <Block>
        <ToggleWrapper onClick={handleBack}>
          <LeftIcon />
        </ToggleWrapper>
        <Heading>
          <ChatAvatars users={[profile_obj]} />
          <NameWrapper>
            <ProfileName>{profile}</ProfileName>

            <ProfileEmail>{profile_obj.email}</ProfileEmail>
          </NameWrapper>
        </Heading>
        <Settings>
          <Dropdown
            className="kt-header__topbar-item kt-header__topbar-item--user user-profile"
            drop="down"
            alignRight
            onSelect={handleSelect}
          >
            <Dropdown.Toggle
              as={CustomToggle}
              id="dropdown-toggle-chat-settings"
            >
              <ToggleWrapper>
                <MoreVert />
              </ToggleWrapper>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
              style={{
                width: "260px",
                transform: "translate3d(-260px, 64px, 0px)",
                padding: "20px",
              }}
            >
              {items.map((item, idx) => {
                const Icon = item.icon;
                return (
                  <Dropdown.Item
                    as={"p"}
                    style={{ all: "unset", cursor: "pointer" }}
                    eventKey={item.value}
                    key={idx}
                  >
                    <DropdownItemWrapper>
                      <p>
                        <Icon width="14" height="14" />
                      </p>
                      <p>{item.title}</p>
                    </DropdownItemWrapper>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Settings>
      </Block>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 16px;
  width: 100%;
  border-bottom: 1px solid rgba(228, 231, 236, 1);
  & .dropdown-toggle::after {
    display: none;
  }
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Heading = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  margin: 0;
  width: 100%;
  padding: 0 10px;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 6px;
`;

const NameWrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: end;
  flex-direction: column;
`;

const ProfileName = styled.p`
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const ProfileEmail = styled.p`
  font-size: 10px;
  margin: 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;
const Settings = styled.div``;

const ToggleWrapper = styled.div`
  border: 1px solid rgba(226, 226, 226, 1);
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
`;

const DropdownItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 6px;
`;
