import {
  SET_CRM_LIST,
  SET_CURRENT_PLAN,
  SET_CRM_FIELDS,
  RESET_CRM_FIELDS,
  SET_CURRENT_MAPPING,
  SET_REFRESH_DATE_GROUPS,
  SET_REFRESH_DATE_USERS,
  SET_REFRESH_DATE_PROFILES,
  SET_ACCOUNT,
  SET_TEMPORARY_FORMS,
  RESET_TEMPORARY_FORM,
  RESET_FORMS,
  ADD_FILTERS_PROFILE_LIST,
  SET_PROFILE,
  TOGGLE_CHAT,
  SET_CHAT_TYPE_VIEW,
  SET_CURRENT_CHAT_ID,
  SET_CURRENT_CHATS_LIST,
  START_LOADING_CHATS,
  STOP_LOADING_CHATS,
  FETCH_CHATS,
  START_LOADING_MESSAGES,
  STOP_LOADING_MESSAGES,
  FETCH_MESSAGES,
  SET_CURRENT_MESSAGE_LIST,
  START_SENDING_MESSAGE,
  STOP_SENDING_MESSAGE,
  SEND_MESSAGE,
  SET_WS_MESSAGE,
  START_READING_MESSAGES,
  STOP_READING_MESSAGES,
  READ_MESSAGES,
  START_PIN_MESSAGES,
  STOP_PIN_MESSAGES,
  PIN_MESSAGES,
  START_UNPIN_MESSAGES,
  STOP_UNPIN_MESSAGES,
  UNPIN_MESSAGES,
  SET_VIEW_TYPE,
  PIN_MESSAGES_ACTION,
  UNPIN_MESSAGES_ACTION,
  READ_MESSAGES_ACTION,
  PINNED_MESSAGES_ACTION,
  OPEN_UPLOAD_MODAL,
  CLOSE_UPLOAD_MODAL,
  SET_FILES,
  REMOVE_FILE,
  GET_MORE_CHATS_ACTION,
  SET_WS_MESSAGE_PINNED,
  SET_WS_MESSAGE_UNPINNED,
  SET_WS_MESSAGE_READ,
  SET_WS_MESSAGE_CHANGE_NAME,
  LIKE_MESSAGES_ACTION,
  DISLIKE_MESSAGES_ACTION,
  ARCIVE_CHAT_ACTION,
  UPDATE_CHAT_ACTION,
} from "./constants";

export const setCurrentMapping = (payload) => {
  return {
    type: SET_CURRENT_MAPPING,
    payload,
  };
};

export const setCRMAction = (payload) => {
  return {
    type: SET_CRM_LIST,
    payload,
  };
};

export const setRefreshDateActionGroups = (payload) => {
  return {
    type: SET_REFRESH_DATE_GROUPS,
    payload,
  };
};

export const setRefreshDateActionUsers = (payload) => {
  return {
    type: SET_REFRESH_DATE_USERS,
    payload,
  };
};

export const setRefreshDateActionProfiles = (payload) => {
  return {
    type: SET_REFRESH_DATE_PROFILES,
    payload,
  };
};

export const setPlanAction = (payload) => {
  return {
    type: SET_CURRENT_PLAN,
    payload,
  };
};

export const setCRMFields = (payload) => {
  return {
    type: SET_CRM_FIELDS,
    payload,
  };
};

export const resetCRMFields = () => {
  return {
    type: RESET_CRM_FIELDS,
  };
};

export const setAccount = (payload) => {
  return {
    type: SET_ACCOUNT,
    payload,
  };
};

export const setTemporaryForms = (payload) => {
  return {
    type: SET_TEMPORARY_FORMS,
    payload,
  };
};

export const resetTemporaryForm = (payload) => {
  return {
    type: RESET_TEMPORARY_FORM,
    payload,
  };
};

export const resetForms = () => {
  return {
    type: RESET_FORMS,
  };
};

export const addFiltersProfileList = (payload) => {
  return {
    type: ADD_FILTERS_PROFILE_LIST,
    payload,
  };
};

export const setProfileAction = (payload) => {
  return {
    type: SET_PROFILE,
    payload,
  };
};

export const toggleChatAction = (payload) => {
  return {
    type: TOGGLE_CHAT,
    payload,
  };
};

export const setViewTypeAction = (payload) => {
  return {
    type: SET_VIEW_TYPE,
    payload,
  };
};

export const setChatViewTypeAction = (payload) => {
  return {
    type: SET_CHAT_TYPE_VIEW,
    payload,
  };
};

export const setChatIdAction = (payload) => {
  return {
    type: SET_CURRENT_CHAT_ID,
    payload,
  };
};

export const setChatsListAction = (payload) => {
  return {
    type: SET_CURRENT_CHATS_LIST,
    payload,
  };
};

export const setMoreChatsAction = (payload) => {
  return {
    type: GET_MORE_CHATS_ACTION,
    payload,
  };
};

export const startLoadingChatsAction = () => ({ type: START_LOADING_CHATS });
export const stopLoadingChatsAction = () => ({ type: STOP_LOADING_CHATS });
export const fetchChatsAction = (params) => ({ type: FETCH_CHATS, params });
export const fetchPinnedMessagesAction = (payload) => ({
  type: PINNED_MESSAGES_ACTION,
  payload,
});

export const readMessageAction = (payload) => ({
  type: READ_MESSAGES_ACTION,
  payload,
});

export const pinMessageAction = (payload) => ({
  type: PIN_MESSAGES_ACTION,
  payload,
});
export const unpinMessageAction = (payload) => ({
  type: UNPIN_MESSAGES_ACTION,
  payload,
});

export const startLoadingMessagesAction = () => ({
  type: START_LOADING_MESSAGES,
});
export const stopLoadingMessagesAction = () => ({
  type: SET_CURRENT_MESSAGE_LIST,
});
export const fetchMesagesAction = (params) => ({
  type: FETCH_MESSAGES,
  params,
});

export const sendMessageAction = (payload) => {
  return {
    type: SEND_MESSAGE,
    payload,
  };
};

export const likeMessageAction = (payload) => {
  return {
    type: LIKE_MESSAGES_ACTION,
    payload,
  };
};

export const dislikeMessageAction = (payload) => {
  return {
    type: DISLIKE_MESSAGES_ACTION,
    payload,
  };
};

export const setWSEventMessageAction = (payload) => {
  return {
    type: SET_WS_MESSAGE,
    payload,
  };
};

export const setWSMessagePinnedAction = (payload) => {
  return {
    type: SET_WS_MESSAGE_PINNED,
    payload,
  };
};

export const setWSMessageUnPinnedAction = (payload) => {
  return {
    type: SET_WS_MESSAGE_UNPINNED,
    payload,
  };
};

export const setWSMessageReadAction = (payload) => {
  return {
    type: SET_WS_MESSAGE_READ,
    payload,
  };
};

export const setWSMessageChangeNameAction = (payload) => {
  return {
    type: SET_WS_MESSAGE_CHANGE_NAME,
    payload,
  };
};

export const openUploadModalAction = () => ({
  type: OPEN_UPLOAD_MODAL,
});
export const closeUploadModalAction = () => ({
  type: CLOSE_UPLOAD_MODAL,
});

export const setFilesAction = (payload) => {
  return {
    type: SET_FILES,
    payload,
  };
};

export const removeFileAction = (payload) => {
  return {
    type: REMOVE_FILE,
    payload,
  };
};

export const archiveChatAction = (payload) => {
  return {
    type: ARCIVE_CHAT_ACTION,
    payload,
  };
};

export const updateChatFiledAction = (payload) => {
  return {
    type: UPDATE_CHAT_ACTION,
    payload,
  };
};
