import axios from "axios";

export const BASE_URL = process.env.REACT_APP_API_URL;

export function uploadImg(data) {
  return axios.post(`${BASE_URL}upload-file`, data);
}

const b64toBlob = (b64Data, contentType = "image/jpg", sliceSize = 512) => {
  const byteCharacters = atob(b64Data);
  const byteArrays = [];

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize);

    const byteNumbers = new Array(slice.length);
    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);
    byteArrays.push(byteArray);
  }

  const blob = new Blob(byteArrays, { type: contentType });
  return blob;
};

export const getImg = (url, base) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${base ? BASE_URL : ""}${url}`, { responseType: "arraybuffer" })
      .then((res) => {
        return new Buffer(res.data, "binary").toString("base64");
      })
      .then((res) => {
        const blob = b64toBlob(res);
        const blobUrl = URL.createObjectURL(blob);
        resolve(blobUrl);
      })
      .catch((error) => {
        // resolve(`${base ? BASE_URL : ""}${url}`);
        reject(error);
        return error;
      });
  });
};
