import React from "react";
import styled from "styled-components";
import { ImgComponent } from "../../../../../../../Sales/helpers/ImgComponent";

const imageExtensions = ["jpg", "jpeg", "png", "bmp", "gif", "heic"];

const renderFile = (file, onDownload) => {
  if (imageExtensions.includes(file.extension)) {
    return (
      <ImgComponent
        style={{ borderRaddius: "4px" }}
        src={file.path}
        alt={file.name}
        className={"message-media"}
        withIndicator={true}
      />
    );
  }

  return (
    <FileType onClick={onDownload}>
      {file.extension.toUpperCase()}{" "}
      <FileName>{`${file.file_name}.${file.extension}`}</FileName>{" "}
    </FileType>
  );
};

const MediaUnit = ({ file, onDownload }) => {
  return <ItemWrapper>{renderFile(file, onDownload)}</ItemWrapper>;
};

export const MessageMedia = ({ files }) => {
  const onClickDownload = () => {};

  return (
    <Wrapper>
      {files.map((file) => (
        <MediaUnit file={file} onDownload={onClickDownload} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .message-media {
    display: block;
    height: auto;
    width: 100%;
    border-radius: 12px;
  }
`;

const ItemWrapper = styled.div`
  margin-bottom: 10px;
`;

const FileType = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 12px;
  color: #555;
  text-transform: uppercase;
  width: 35px;
  height: 22px;
  padding: 2px 4px 2px 4px;
  border-radius: 4px;
  opacity: 0px;
  font-family: "Inter";
  font-size: 14px;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: -0.03em;
  text-align: left;
  background-color: black;
  color: white;
  cursor: pointer;
`;

const FileName = styled.p`
  margin: 0;
  color: black;
`;
