import { all } from "redux-saga/effects";
import { combineReducers } from "redux";
import * as auth from "./ducks/auth.duck";
import { metronic } from "../../_metronic";
import { userInfoReducer } from "./reducers/userInfoReducer";
import { fieldsCRMReducer } from "./reducers/fieldsCRMReducer";
import { applicationDataReducer } from "./reducers/applicationDataReducer";
import { temporaryDataReducer } from "./reducers/temporaryDataReducer";
import { chatReducer } from "./reducers/chatReducer";
import {
  watchFetchChatsSaga,
  watchFetchMoreChatsSaga,
  watchFetchMessagesSaga,
  watchCurrentChatIdChange,
  watchSendMessage,
  watchPinMessage,
  watchUnpinMessage,
  watchReadMessage,
  watchPinnedMessage,
  watchLikeMessage,
  watchDislikeMessage,
  watchArchiveChat,
  watchUpdateChat,
} from "./operations/chatsOperations";

export const rootReducer = combineReducers({
  auth: auth.reducer,
  i18n: metronic.i18n.reducer,
  builder: metronic.builder.reducer,
  user_info: userInfoReducer,
  crmFields: fieldsCRMReducer,
  appData: applicationDataReducer,
  temporaryData: temporaryDataReducer,
  chat: chatReducer,
});

export function* rootSaga() {
  yield all([
    auth.saga(),
    watchFetchChatsSaga(),
    watchFetchMessagesSaga(),
    watchCurrentChatIdChange(),
    watchSendMessage(),
    watchPinMessage(),
    watchUnpinMessage(),
    watchReadMessage(),
    watchPinnedMessage(),
    watchFetchMoreChatsSaga(),
    watchLikeMessage(),
    watchDislikeMessage(),
    watchArchiveChat(),
    watchUpdateChat(),
  ]);
}
