import React from "react";
import styled from "styled-components";
import { SentIcon } from "../../../../../../assets";
import { TextField } from "@material-ui/core";
import UploadFile from "./components/UploadFile/UploadFile";
import { useDispatch, useSelector } from "react-redux";
import { openUploadModalAction } from "../../../../../store/actions";
import { AttachmentsIcon } from "../../../../../../assets";
import { Badge } from "@material-ui/core";

export const SendMessage = ({ onChange, value, onSend }) => {
  const dispatch = useDispatch();
  const files = useSelector((state) => state.chat.files);
  const onOpenModal = () => {
    dispatch(openUploadModalAction());
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault();
      onSend();
    }
  };

  return (
    <Wrapper>
      <AttachmentsBlock>
        <span onClick={onOpenModal} style={{ margin: "10px 0" }}>
          <Badge color="primary" badgeContent={files?.length}>
            <AttachmentsIcon />
          </Badge>
        </span>
      </AttachmentsBlock>
      <InputBlock>
        <TextField
          minRows={2}
          placeholder="Type a message here"
          type="text"
          name="text"
          multiline
          fullWidth
          value={value.text}
          onKeyDown={handleKeyDown}
          onChange={(e) => {
            onChange({ target: { name: "text", value: e.target.value } });
          }}
        />
      </InputBlock>
      <ButtonBlock onClick={onSend}>
        <SentIcon />
      </ButtonBlock>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 50px 1fr 50px;
  border-top: 1px solid #e2e2e2;
  padding: 16px;
  cursor: pointer;
  padding-bottom: 0;
  & .MuiInput-underline:before {
    display: none;
  }
`;

const AttachmentsBlock = styled.div``;
const InputBlock = styled.div``;
const ButtonBlock = styled.div``;
