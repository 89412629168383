import React, { createContext, useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Pusher from "pusher-js";
import { setWSEventMessageAction } from "../../store/actions";

export const SocketContext = createContext();

export const SocketProvider = ({ children }) => {
  const meId = useSelector((state) => state?.auth?.user?.id);
  const accountId = useSelector((state) => state.appData.account.id);
  const dispatch = useDispatch();
  const [token, setToken] = useState(null);
  const [pusher, setPusher] = useState(null);
  const [channel, setChannel] = useState(null);
  const [chatRoom, setChatRoom] = useState(null);

  useEffect(() => {
    if (token && meId && accountId) {
      const newPusher = new Pusher(process.env.REACT_APP_PUSHER_APP_KEY, {
        wsHost: process.env.REACT_APP_SOCKET_URL,
        wsPort: process.env.REACT_APP_PUSHER_PORT,
        authEndpoint: process.env.REACT_APP_SOCKET_URL_AUTH,
        forceTLS: false,
        encrypted: true,
        disableStats: true,
        enabledTransports: ["ws", "wss"],
        cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
        auth: {
          headers: {
            Authorization: `Bearer ${token}`,
            "Access-Control-Allow-Origin": "*",
          },
        },
      });

      //const chatRoomChannel = newPusher.subscribe(`private-chat-rooms`);

      const chatRoomChannel = newPusher.subscribe(
        `private-account.${accountId}.chat-rooms`
      );

      setPusher(newPusher);
      setChannel(chatRoomChannel);
      setChatRoom(chatRoomChannel);
    }
  }, [token, meId, accountId]);

  return (
    <SocketContext.Provider value={{ pusher, channel, setToken, chatRoom }}>
      {children}
    </SocketContext.Provider>
  );
};
