import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import Loader from '../../../../components/Loader';
import * as AlertState from '../../../../store/ducks/auth.duck';
import { useDispatch, useSelector } from 'react-redux';
import catchErrorMessage from '../../../../helpers/errorCatcher';
import * as Yup from 'yup';
import { TextField } from "@material-ui/core";
import { Button } from 'react-bootstrap';
import SimpleDropdown from '../../../../components/SimpleDropdown';
import DatePicker from '../../../../components/DatePicker'
import { DragAndDropWorkout } from './DragAndDropWorkout/DragAndDropWorkout';
import {
    fetchExercise,
    fetchWorkout,
    getWorkoutsProgram,
    fetchExercises,
    fetchExercisesForDropdown,
} from '../../../../crud/library.crud';
import { ExerciseData } from './ExerciseData/ExerciseData';
import { useHistory, useLocation } from 'react-router-dom';
import { validateFullForm } from './validations';
import { getNotesV2 } from '../../../../crud/notes.crud';
import './create-workout.scss';
import { submitForm } from './submitForm';
import { handleAddSection, handleAddExercise, putNumbersFunc } from './functions';
import { AddExerciseModal } from './AddExerciseModal/AddExerciseModal';
import moment from 'moment';

const ValidationSchema = Yup.object().shape({
    title: Yup.string()
        .min(2, 'Too short - should be at least 2 characters')
        .max(100, 'Too long - should not exceed 100 characters')
        .required('Required'),
    time: Yup.number()
        .min(0, 'Duration is invalid')
        .max(999, 'Duration is invalid')
        .nullable(),
    access: Yup.string()
        .required('Required'),
    description: Yup.string()
        .min(2, 'Too short - should be at least 2 characters')
        .max(1000, 'Too long - should not exceed 1000 characters')
        .nullable(),
});

const sectionsAndExercisesArray = [
    {
        id: 'drag-' + Math.random(),
        hasError: false,
        touched: false,
        errorText: '',
        title: '',
        type: 'section',
        order: 0,
    },
    {
        id: 'drag-' + Math.random(),
        hasError: false,
        touched: false,
        errorText: '',
        title: '',
        type: 'exercise',
        order: 1,
        number: 1
    },
];

export const CreateEditWorkout = ({ match }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const location = useLocation();
    const [loader, setLoader] = useState(false);
    const [exercise, setExercise] = useState(null);
    const [exerciseData, setExerciseData] = useState(null);
    const [dropdown, setDropdown] = useState(false);
    const buttonRef = useRef();
    const [cloner, setCloner] = useState(null);
    const [originalExercises, setOriginalExercises] = useState([]);
    const [sectionsAndExercises, setSectionsAndExercises] = useState(sectionsAndExercisesArray);
    const profileId = match.params.profileId;
    const workoutId = match.params.id || location.state?.workoutId;
    const [openedNotes, setOpenedNotes] = useState([]);
    const [completeWorkout, setCompleteWorkout] = useState(false);
    const [completed_date, set_completed_date] = useState(null);
    const [exerciseModal, setExerciseModal] = useState(false);
    const [exercisesList, setExercisesList] = useState( []);
    const [refreshExercises, setRefreshExercises] = useState(false);


    const programId = location.pathname.includes('program')
        ? location.pathname.split('/')[6]
        : null

    // library page
    const isCreateForLibrary = !!(!workoutId && !profileId);
    const isEditForLibrary = !!(workoutId && !cloner && !profileId);

    // all pages
    const isCreateForProfile = match.path === '/profile/:profileId/workouts/create';

    // profile program page
    const isEditForProfileFromLibrary = !!(profileId && workoutId && location.pathname.includes('edit') && !cloner)
    const isCloneFromLibrary = !!(profileId && workoutId && location.pathname.includes('duplicate') && !cloner)
    const editAlreadyCloned = !!(profileId && workoutId && location.pathname.includes('edit') && cloner)
    const isCloneAlreadyCloned = !!(profileId && workoutId && location.pathname.includes('duplicate') && cloner)

    // note button
    const displayNote = isCloneAlreadyCloned || isCloneFromLibrary || isCreateForProfile || isEditForProfileFromLibrary || editAlreadyCloned
    const subscription = useSelector(state => state?.user_info?.subscription?.plan?.name);

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: {
            title: '',
            time: '',
            description: '',
            access: 'myself',
        },
        validationSchema: ValidationSchema,
        onSubmit: (values) => {
            submitForm(
                values,
                completed_date,
                setLoader,
                sectionsAndExercises,
                originalExercises,
                profileId,
                isEditForLibrary,
                match,
                dispatch,
                completeWorkout,
                location,
                history,
                isCreateForLibrary,
                isCreateForProfile,
                isEditForProfileFromLibrary,
                isCloneFromLibrary,
                editAlreadyCloned,
                isCloneAlreadyCloned,
                programId
            )
        },
    });

    useEffect(() => {
        loadExercises();
    }, [refreshExercises]);

    const loadExercises = (search) => {
        setLoader(true);
        fetchExercisesForDropdown({ search, perPage: 1000 })
            .then(res => {
                setExercisesList(res.data.exercises);
                setLoader(false);
            })
            .catch(() => {
                setLoader(false);
            })
    };

    useEffect(() => {
        function clickOutside(e) {
            if (buttonRef && !buttonRef?.current?.contains(e.target)) {
                setDropdown(false);
            }
        };

        document.addEventListener('mousedown', clickOutside);
        return () => document.removeEventListener('mousedown', clickOutside);
    }, [buttonRef]);

    useEffect(() => {
        // display exercise
        if (exercise?.id) {
            setLoader(true)
            fetchExercise(exercise.id)
                .then(res => {
                    setExerciseData(res.data.data);
                })
                .catch(err => {
                    let errorMessage = catchErrorMessage(err) || 'Fetch exercise error';
                    dispatch(AlertState.actions.alert({
                        text: errorMessage,
                        variant: false
                    }))
                })
                .finally(() => setLoader(false))
        }
    }, [exercise]);

   

   
    const parseWorkout = (res) => {
        formik.setValues({
            title: isCloneAlreadyCloned || isCloneFromLibrary ? 'Copy of ' + res.data.data.title : res.data.data.title,
            time: res.data.data.time,
            description: res.data.data.description,
            access: res.data.data.access,
        });
        if (res.data.data.cloner) setCloner(res.data.data.cloner);

        const fetchedExercises = res.data.data.exercises.map(elem => ({ ...elem, id: elem.id + '', type: 'exercise' }));
        // sections parsing
        let fetchedSections = [];
        let iterationNumber = 1;
        let sectionsOrder = 0;
        res.data.data.sections.forEach(section => {
            if (section.order) {
                sectionsOrder = section.order;
            } else if (iterationNumber === 1) {
                sectionsOrder = fetchedExercises.length + 1
            } else {
                sectionsOrder += section.exercises.length + 1;
            }
            fetchedSections.push({
                title: section.title,
                order: sectionsOrder,
                id: section.id + '',
                type: 'section',
                exercises: section.exercises || [],
            })
            iterationNumber += 1
        })

        // exercises under sections parsing
        const fetchedExercisesUnderSections = [];
        fetchedSections.forEach(section => {
            let order = section.order;
            section.exercises.forEach(exercise => {
                fetchedExercisesUnderSections.push({
                    ...exercise,
                    id: exercise.id + '',
                    type: 'exercise',
                    order: typeof exercise.order !== 'number' ? order + 1 : exercise.order,
                })
                order++;
            })
        });

        // delete exercises from sections
        fetchedSections = fetchedSections.map(elem => {
            return {
                title: elem.title,
                order: elem.order,
                id: elem.id + '',
                type: 'section',
            }
        });

        setOriginalExercises([...fetchedExercises, ...fetchedExercisesUnderSections]);
        let fetchedData = [...fetchedSections, ...fetchedExercises, ...fetchedExercisesUnderSections];
        fetchedData = fetchedData.sort((a, b) => a.order - b.order);

        const result = putNumbersFunc(fetchedData)
        setSectionsAndExercises(result);

        if (profileId) {
            setTimeout(() => {
                fetchedData
                    .filter(elem => elem.type === 'exercise')
                    .forEach((exercise) => {
                        getNotesV2(profileId, { exercise_id: exercise.id })
                            .then(res => {
                                if (res.data.data[0]?.note_text) {
                                    setSectionsAndExercises((prev) => {
                                        let arr = [...prev]
                                        const index = prev.findIndex(elem => +elem.id === +exercise.id)
                                        arr[index].note = {
                                            note_text: res.data.data[0]?.note_text || '',
                                            id: res.data.data[0]?.id || null
                                        }
                                        return arr
                                    })
                                }
                            })
                    })
            }, 500)
        };
    }

    useEffect(() => {
        // fetch workout if is edit
        if (workoutId) {
            setLoader(true);

            if (programId) {
                getWorkoutsProgram(profileId, programId)
                    .then((res) => {
                        if (res.data.data.completed_date) {
                            set_completed_date(moment(res.data.data.completed_date).format('MM/DD/YYYY'))
                        }
                        parseWorkout(res)
                    })
                    .catch(err => {
                        let errorMessage = catchErrorMessage(err) || 'Fetch workout error';
                        dispatch(AlertState.actions.alert({
                            text: errorMessage,
                            variant: false
                        }))
                        if (errorMessage === 'Element does not found') {
                            history.push('/library/workouts/list')
                        };
                    })
                    .finally(() => setLoader(false))
            } else {
                fetchWorkout(workoutId)
                    .then((res) => {
                        parseWorkout(res)
                    })
                    .catch(err => {
                        let errorMessage = catchErrorMessage(err) || 'Fetch workout error';
                        dispatch(AlertState.actions.alert({
                            text: errorMessage,
                            variant: false
                        }))
                        if (errorMessage === 'Element does not found') {
                            history.push('/library/workouts/list')
                        };
                    })
                    .finally(() => setLoader(false))
            }
        }
    }, [location]);

    const onAddSection = () => handleAddSection(setSectionsAndExercises)
    const onAddExercise = () => handleAddExercise(setSectionsAndExercises, sectionsAndExercises)

    // console.log('sectionsAndExercises',sectionsAndExercises)
    

    const onOpenExerciseModal = () => {
        setExerciseModal(true)
    };

    const handleRefreshExercises = () => {
        setRefreshExercises(prev => !prev)
    };

    return (
        <div className='create-workout'>
            <Loader visible={loader} />

            {exerciseModal && (
                <AddExerciseModal
                    modal={exerciseModal}
                    setModal={setExerciseModal}
                    refreshExercises={handleRefreshExercises}
                />
            )}

            <div className='create-workout__page-title'>
                {match.params.id ? 'Edit Workout' : 'Create Workout'}
            </div>

            <form onSubmit={formik.onsubmit}>
                <div className='create-workout__body'>
                    <div className='create-workout__first-block'>
                        <div className='create-workout__page-header'>
                            <div className='create-workout__textfield'>
                                <TextField
                                    name="title"
                                    variant="outlined"
                                    type="text"
                                    onMouseDown={e => e.stopPropagation()}
                                    label='Workout Name'
                                    inputProps={{ maxLength: 100 }}
                                    style={{ width: '100%' }}
                                    error={Boolean(formik.errors.title && formik.touched.title)}
                                    helperText={formik.touched.title && formik.errors.title}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.title}
                                    onChange={formik.handleChange}
                                />
                            </div>

                            <div className='create-workout__row-container' style={{ marginTop: 25 }}>
                                <div className='d-flex'>
                                    <TextField
                                        name="time"
                                        variant="outlined"
                                        type="number"
                                        label='Time'
                                        inputProps={{
                                            min: 0,
                                            max: 999,
                                        }}
                                        className='create-workout__time-input'
                                        error={Boolean(formik.errors.time && formik.touched.time)}
                                        helperText={formik.touched.time && formik.errors.time}
                                        onBlur={formik.handleBlur}
                                        value={formik.values.time}
                                        onChange={formik.handleChange}
                                        onKeyPress={(e) => {
                                            if ((+e.which !== 8 && +e.which !== 0 && +e.which < 48) || +e.which > 57) {
                                                e.preventDefault();
                                            }
                                        }}
                                    />
                                    <div style={{ margin: '12px 5px 0 5px' }}>min</div>
                                </div>

                                {profileId && <div className='create-workout__datepicker'>
                                    <DatePicker
                                        required={false}
                                        label="Completed Date"
                                        future={false}
                                        format={'MM/DD/YYYY'}
                                        value={completed_date}
                                        onChange={(value) => set_completed_date(
                                            value === '  /  /    '
                                                ? null
                                                : value,
                                        )}
                                    />
                                </div>}

                                <div className='create-workout__dropdown'>
                                    <SimpleDropdown
                                        width="100%"
                                        name="access"
                                        label="Access"
                                        value={formik.values.access === 'myself'
                                        ? 'Myself'
                                        : formik.values.access === 'share'
                                            ? 'Shared with All Users'
                                            : formik.values.access === 'share_groups' ? 'Shared with Group' : ''
                                    }
                                        onChange={(value) => formik.handleChange({
                                            target: {
                                                value: value === 'Myself'
                                                    ? 'myself'
                                                    : value === 'Shared with All Users'
                                                    ? 'share'
                                                    : value === 'Shared with Group' 
                                                    ? 'share_groups' 
                                                    : '',
                                                name: 'access'
                                            }
                                        })}
                                        error={Boolean(formik.touched.access && formik.errors.access)}
                                        helperText={formik.touched.access && formik.errors.access}
                                        options={
                                            subscription === 'Enterprise' 
                                        ? 
                                            [
                                            'Myself',
                                            'Shared with All Users',
                                            'Shared with Group'
                                            ] 
                                        : 
                                            subscription === 'Gym' 
                                            ? 
                                            [
                                                'Myself',
                                                'Shared with All Users',
                                            ] 
                                            : 
                                            []
                                        }
                                        onBlur={formik.handleBlur}
                                    />
                                </div>
                            </div>

                            <div className='create-workout__textfield' style={{ marginBottom: 25, paddingTop: 0 }}>
                                <TextField
                                    name='description'
                                    label='Note'
                                    variant='outlined'
                                    type='text'
                                    multiline
                                    rows={3}
                                    style={{ width: '100%' }}
                                    inputProps={{ maxLength: 1000 }}
                                    onChange={formik.handleChange}
                                    value={formik.values.description}
                                    error={!!(formik.touched.description && formik.errors.description)}
                                    helperText={formik.touched.description && formik.errors.description}
                                    onBlur={formik.handleBlur}
                                />
                            </div>
                        </div>

                        <DragAndDropWorkout
                            sectionsAndExercises={sectionsAndExercises}
                            setSectionsAndExercises={setSectionsAndExercises}
                            setLoader={setLoader}
                            setExercise={setExercise}
                            displayNote={displayNote}
                            originalExercises={originalExercises}
                            profileId={profileId}
                            openedNotes={openedNotes}
                            setOpenedNotes={setOpenedNotes}
                            exercisesList={exercisesList}
                        />

                        <div style={{ display: 'flex' }}>
                            <div className='create-workout__section-button' style={{ marginLeft: 20 }}>
                                <Button
                                    variant="primary"
                                    onClick={onAddSection}
                                >Add Section</Button>
                            </div>

                            <div className='create-workout__section-button'>
                                <Button
                                    variant="primary"
                                    onClick={() => onAddExercise()}
                                >Add Exercise</Button>
                            </div>

                            <div className='create-workout__section-button'>
                                <Button
                                    variant="primary"
                                    onClick={onOpenExerciseModal}
                                >Create Exercise</Button>
                            </div>
                        </div>

                        {isEditForProfileFromLibrary ||
                            isCloneAlreadyCloned ||
                            isCloneFromLibrary ||
                            editAlreadyCloned
                            ? (
                                <div className='d-flex' style={{ gap: '20px', marginLeft: 20 }}>
                                    <div style={{ position: 'relative' }}>
                                        <Button
                                            className="btn-blue"
                                            onClick={() => setDropdown(true)}
                                            style={{ whiteSpace: 'nowrap', margin: 5 }}
                                        >Save Workout</Button>
                                        <div
                                            className="buttons-dropdown"
                                            style={{
                                                display: `${dropdown ? '' : 'none'}`,
                                                left: '0',
                                                bottom: '4px',
                                            }}
                                            ref={buttonRef}
                                        >
                                            <div className="buttons-dropdown__elem">
                                                <span
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        formik.validateForm()
                                                            .then((res) => {
                                                                formik.setErrors(res);
                                                                formik.setTouched({
                                                                    title: true,
                                                                    time: true,
                                                                    description: true,
                                                                    access: true,
                                                                });

                                                                if (validateFullForm(sectionsAndExercises, setSectionsAndExercises, dispatch)) {
                                                                    formik.handleSubmit();
                                                                }
                                                            })
                                                    }}
                                                    disabled={formik.isSubmitting}
                                                >
                                                    Save Workout
                                                </span>
                                            </div>
                                            <div className="buttons-dropdown__elem">
                                                <span
                                                    onClick={(e) => {
                                                        e.preventDefault();
                                                        setCompleteWorkout(true);
                                                        formik.validateForm()
                                                            .then((res) => {
                                                                formik.setErrors(res);
                                                                formik.setTouched({
                                                                    title: true,
                                                                    time: true,
                                                                    description: true,
                                                                    access: true,
                                                                });

                                                                if (validateFullForm(sectionsAndExercises, setSectionsAndExercises, dispatch)) {
                                                                    formik.handleSubmit();
                                                                }
                                                            })
                                                    }}
                                                    disabled={formik.isSubmitting}
                                                >
                                                    {'Save & Complete Workout'}
                                                </span>
                                            </div>
                                            <div
                                                className="buttons-dropdown__arrow"
                                                onClick={() => setDropdown(false)}
                                            >{'>'}</div>
                                        </div>
                                    </div>

                                    <Button
                                        onClick={() => history.push(`/profile/${profileId}/program`)}
                                        style={{ whiteSpace: 'nowrap', margin: 5 }}
                                    >Close Workout</Button>
                                </div>
                            ) : (
                                <div className='create-workout__section-button'>
                                    <Button
                                        type='submit'
                                        variant="primary"
                                        className='btn-blue'
                                        onClick={(e) => {
                                            e.preventDefault();
                                            formik.validateForm()
                                                .then((res) => {
                                                    formik.setErrors(res);
                                                    formik.setTouched({
                                                        title: true,
                                                        time: true,
                                                        description: true,
                                                        access: true,
                                                    });

                                                    if (validateFullForm(sectionsAndExercises, setSectionsAndExercises, dispatch)) {
                                                        formik.handleSubmit();
                                                    }
                                                })
                                        }}
                                    >
                                        {isCreateForLibrary || isCreateForProfile ? 'Create Workout' : 'Save Workout'}
                                    </Button>
                                </div>
                            )}
                    </div>

                    <div className='create-workout__second-block'>
                        {exercise && exerciseData
                            ? (
                                <ExerciseData
                                    exerciseData={exerciseData}
                                    setExercise={setExercise}
                                    setExerciseData={setExerciseData}
                                />
                            )
                            : (
                                <div className='create-workout__no-exercise'>
                                    Select an exercise to see details
                                </div>
                            )
                        }
                    </div>
                </div>
            </form>
        </div>
    )
};
