import React, { useState } from "react";
import styled from "styled-components";
import { formatTime } from "../../../../helpers/formatTime";
import { ReadByBlock } from "./components/ReadByBlock/ReadByBlock";
import { MessageActions } from "./components/MessageActions/MessageActions";
import { PinMessage } from "../../../../../../../../assets";
import { MessageMedia } from "./components/MessageMedia/MessageMedia";
import { ParseLinks } from "../../../../helpers/ParseLinks";
import { useSelector } from "react-redux";
import { LikeByBlock } from "./components/LikeByBlock /LikeByBlock";
import { ChatAvatars } from "../../../Chats/components/ChatsList/components/ChatAvatars/ChatAvatars";
import { ImgComponent } from "../../../../../Sales/helpers/ImgComponent";
import { Avatar, Tooltip } from "@material-ui/core";

const SingleAvatar = ({ user }) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  const handleImageError = () => {
    setImageLoaded(false);
  };

  return (
    <SingleAvatarWraper>
      <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
        <span>
          {user?.pic && imageLoaded ? (
            <ImgComponent
              onError={handleImageError}
              src={user.pic}
              alt="img"
              className="auto-photo"
              withIndicator={true}
            />
          ) : (
            <Avatar className="auto-photo">{`${user?.first_name[0]}${user?.last_name[0]}`}</Avatar>
          )}
        </span>
      </Tooltip>
    </SingleAvatarWraper>
  );
};

export const Message = ({ message, isMy }) => {
  const meId = useSelector((state) => state.auth.user.id);

  const isLiked = message?.liked_by?.includes(meId);
  return (
    <Container>
      {!isMy && <SingleAvatar user={message?.created_by} />}

      <Wrapper isMy={isMy} isPinned={!!message?.is_pinned}>
        <BodyWrapper>
          <Body>
            <MessageText isPinned={!!message?.is_pinned} isMy={isMy}>
              <ParseLinks isMy={isMy} text={message.text} />
            </MessageText>
            {!!message?.files?.length && <MessageMedia files={message.files} />}
          </Body>

          <MessageActions
            messageId={message.id}
            isPinned={!!message?.is_pinned}
            isLiked={isLiked}
            isMy={isMy}
            text={message.text}
            message={message}
            meId={meId}
          />
        </BodyWrapper>
        <FooterWrapper>
          <WrapperInner>
            {!!message?.is_pinned && <PinMessage color="red" />}
            {isLiked && (
              <LikeByBlock
                likedBy={message?.liked_by}
                users={message?.read_by}
              />
            )}
            {/* <ReadByBlock users={message?.read_by} /> */}
          </WrapperInner>
          <MessageTime isPinned={!!message?.is_pinned} isMy={isMy}>
            {formatTime(message.created_at)}
          </MessageTime>
        </FooterWrapper>
      </Wrapper>
    </Container>
  );
};

const SingleAvatarWraper = styled.div`
  & .auto-photo {
    width: 24px;
    height: 24px;
  }
`;

const Container = styled.article`
  width: 100%;
  display: flex;
  gap: 8px;
`;

const Wrapper = styled.div`
  position: relative;
  width: 280px;
  margin-bottom: 8px;
  padding: 8px 12px;
  background-color: ${(props) =>
    props.isPinned ? "#DB000012" : props.isMy ? "#3E6DF6" : "white"};
  border: 1px solid #e2e2e2;
  border-radius: ${(props) =>
    props.isMy ? "12px 12px 0 12px" : "0px 12px 12px 12px"};
  margin-left: ${(props) => (props.isMy ? "auto" : "0")};
  min-height: 54px;

  & .message-actions {
    display: none;
    transition: all 0.2s;
  }
  &:hover .message-actions {
    display: block;
    transition: all 0.2s;
  }
`;

const FooterWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
`;
const BodyWrapper = styled.div`
  display: grid;
  grid-template-columns: 95% 5%;
`;

const Body = styled.div``;

const MessageText = styled.p`
  color: ${(props) => (props.isMy && !props.isPinned ? "white" : "#1D1D1D")};
  word-break: break-all;
`;
const MessageTime = styled.p`
  color: ${(props) => (props.isMy && !props.isPinned ? "white" : "#9E9E9E")};
  margin: 0;
  position: absolute;
  right: 10px;
  bottom: 4px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
  letter-spacing: -0.03em;
  text-align: right;
`;

const WrapperInner = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
`;
