import React, { useState } from "react";
import styled from "styled-components";
import { ImgComponent } from "../../../../../Sales/helpers/ImgComponent";
import { Avatar } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const MemberItem = ({ member }) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  const handleImageError = () => {
    setImageLoaded(false);
  };
  return (
    <ItemWrapper>
      <UserImageWrapper>
        {member?.pic && imageLoaded ? (
          <ImgComponent
            src={member.pic}
            alt="img"
            className="read-by-photo"
            onError={handleImageError}
            withIndicator={true}
          />
        ) : (
          <Avatar className="read-by-photo">{`${member?.first_name[0]}${member?.last_name[0]}`}</Avatar>
        )}
      </UserImageWrapper>
      <UserBodyWrapper>
        <Link to={`/profile/${member.id}/overview`}>{member.full_name}</Link>
      </UserBodyWrapper>
    </ItemWrapper>
  );
};

export const Member = ({ chatRoomId }) => {
  const { participants: members } = useSelector((state) =>
    state.chat.chats.chatList.find((item) => item.id === chatRoomId)
  );

  return (
    <Wrapper>
      {members.map((item) => (
        <MemberItem key={item.id} member={item} />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const ItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 20% 80%;
  margin-bottom: 20px;
  align-items: center;
`;

const UserImageWrapper = styled.div``;
const UserBodyWrapper = styled.div``;
