import { CloseOutlined } from "@material-ui/icons";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import { fetchPinnedMessagesAction } from "../../../../../../../../../store/actions";
import { useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import { Message } from "../../Message";

export const PinnedMessages = ({ chatRoomId }) => {
  const dispatch = useDispatch();
  const items = useSelector((state) => state.chat.pinned);
  const [visible, seVisible] = useState(true);
  const [modal, setModal] = useState(false);

  const handleOpenModal = () => {
    setModal(true);
  };
  const handleCloseModal = () => {
    setModal(false);
  };

  const toggleVisible = () => {
    seVisible((prev) => !prev);
  };

  useEffect(() => {
    if (chatRoomId) dispatch(fetchPinnedMessagesAction({ chatRoomId }));
  }, [chatRoomId]);

  return (
    !!items?.length &&
    visible && (
      <>
        {" "}
        <Wrapper onClick={handleOpenModal}>
          <BlockLeft></BlockLeft>
          <BlockCenter>
            <Title>{`Pinned message (${items.length})`}</Title>
            <MessageBody>{items?.[0]?.text}</MessageBody>
          </BlockCenter>
          <BlockRight onClick={toggleVisible}>
            <CloseOutlined />
          </BlockRight>
        </Wrapper>
        <Modal show={modal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Pinned message</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {items.map((item) => (
              <Message key={item.id} message={item} />
            ))}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    )
  );
};

const Wrapper = styled.div`
  box-shadow: 0px 8px 30px 0px #00000008;
  padding: 8px 0px;
  display: grid;
  grid-template-columns: 5% 80% 5%;
  align-items: center;
  cursor: pointer;
`;

const Title = styled.p`
  color: #f27232;
`;

const BlockLeft = styled.div`
  width: 2px;
  height: 100%;
  background-color: #f27232;
`;
const BlockCenter = styled.div``;
const BlockRight = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const MessageBody = styled.div``;
