import React, { useState } from "react";
import { ImgComponent } from "../../../../../../../Sales/helpers/ImgComponent";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";

const MultyAvatar = ({ user }) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  const handleImageError = () => {
    setImageLoaded(false);
  };

  return (
    <MultyAvatarWraperInner>
      <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
        {user?.pic && imageLoaded ? (
          <ImgComponent
            src={user.pic}
            alt="img"
            className="read-by-photo"
            onError={handleImageError}
            withIndicator={true}
          />
        ) : (
          <Avatar className="read-by-photo">{`${user?.first_name[0]}${user?.last_name[0]}`}</Avatar>
        )}
      </Tooltip>
    </MultyAvatarWraperInner>
  );
};

export const ReadByBlock = ({ users }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const displayedUsers = users.slice(0, 3);
  const remainingUsers = users.slice(3);

  const handleRemainingUsersClick = () => {
    setTooltipVisible(!tooltipVisible);
  };
  return (
    <Wrapper>
      {displayedUsers.map((user) => (
        <MultyAvatar key={user.id} user={user} />
      ))}
      {!!remainingUsers.length && (
        <Tooltip
          title={
            <div>
              {remainingUsers.map((user, index) => (
                <p key={index} variant="body2">
                  {`${user.first_name} ${user.last_name}`}
                </p>
              ))}
            </div>
          }
          open={tooltipVisible}
          onClose={() => setTooltipVisible(false)}
          onOpen={() => setTooltipVisible(true)}
        >
          <span onClick={handleRemainingUsersClick}>
            +{remainingUsers.length}
          </span>
        </Tooltip>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const MultyAvatarWraperInner = styled.div`
  &:not(:first-child) {
    margin-left: -4px;
  }
  & .read-by-photo {
    width: 24px;
    height: 24px;
  }
`;
