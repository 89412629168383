import moment from "moment";

export const formatTime = (dateString) => {
  const date = moment(dateString);
  const now = moment();

  if (date.isSame(now, "day")) {
    return date.format("h:mm A");
  } else if (date.isSame(now, "month")) {
    return date.format("MMM D");
  } else {
    return date.format("YYYY-MM-DD");
  }
};

export const formatTimeToView = (timestamp) => {
  const now = moment();
  const messageTime = moment(timestamp);

  if (now.diff(messageTime, "hours") < 1) {
    const minutesAgo = now.diff(messageTime, "minutes");
    return `${minutesAgo}m. ago`;
  } else if (now.isSame(messageTime, "day")) {
    const hoursAgo = now.diff(messageTime, "hours");
    if (hoursAgo >= 1) {
      return `${hoursAgo} hr${hoursAgo !== 1 ? "s" : ""} ago`;
    } else {
      return messageTime.format("HH:mm");
    }
  } else {
    return messageTime.format("MM/DD/YYYY");
  }
};
