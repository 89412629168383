import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { CloseOutlined } from "@material-ui/icons";
import { EditIconOutlined } from "../../../../../../../../assets";
import { setViewTypeAction } from "../../../../../../../store/actions";
import { useDispatch } from "react-redux";
import { VIEW_MESSAGING_TYPE } from "../../../../constants";
import { Modal, Button } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { updateChatFiledAction } from "../../../../../../../store/actions";
import { useSelector } from "react-redux";

export const SettingsHeader = ({ currentChatId }) => {
  const { name } = useSelector((state) =>
    state.chat.chats.chatList.find((item) => item.id === currentChatId)
  );

  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [title, setTitle] = useState(name);

  const handleBack = () => {
    dispatch(setViewTypeAction(VIEW_MESSAGING_TYPE.MESSAGES));
  };

  const handleEdit = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTitle("");
  };

  const handleSave = () => {
    dispatch(
      updateChatFiledAction({
        name: "name",
        value: title,
        chatRoomId: currentChatId,
      })
    );
    handleClose();
  };

  useEffect(() => {
    setTitle(name);
  }, [name]);

  return (
    <Wrapper>
      <WrapperInner>
        <span onClick={handleBack}>
          <CloseOutlined />
        </span>
        <Title>Chat Settings</Title>
        <span style={{ cursor: "pointer" }} onClick={handleEdit}>
          <EditIconOutlined />
        </span>
      </WrapperInner>
      <Modal show={open} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="title">{`Group Name`}</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <TextField
              name="name"
              style={{ width: "100%" }}
              value={title}
              variant="outlined"
              type="text"
              label="Group Name"
              inputProps={{ maxLength: 100 }}
              onChange={(e) => setTitle(e.target.value)}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button
            className="btn btn-light"
            variant="secondary"
            onClick={handleClose}
          >
            Cancel
          </Button>

          <Button className="btn-blue" variant="primary" onClick={handleSave}>
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px;
  width: 100%;
  border-bottom: 1px solid rgba(228, 231, 236, 1);
`;
const WrapperInner = styled.div`
  display: grid;
  grid-template-columns: 10% 1fr 10%;
  align-items: center;
`;
const Title = styled.p`
  font-family: "Inter";
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  text-align: center;
  margin: 0;
`;
