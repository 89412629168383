import React, { useState } from "react";
import styled from "styled-components";
// import { Redirect } from "react-router-dom";
import { Checkbox } from "@material-ui/core/";
import ArrowDown from "@material-ui/icons/ArrowDropDown";
import { ArrowBottomIcon } from "../../assets";

const MonthWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 200px;
  justify-content: space-between;
`;

const ArrowWraper = styled.span`
  transform: ${(props) => (props.isActive ? "rotate(180deg)" : "rotate(0deg)")};
  transition: all 0.2s;
`;

const RenderMonthColumn = ({ value, isActive }) => {
  return (
    <MonthWrapper>
      <span>{value}</span>
      <ArrowWraper isActive={isActive}>
        <ArrowBottomIcon />
      </ArrowWraper>
    </MonthWrapper>
  );
};

export default ({
  header,
  data,
  check,
  setCheck,
  sort,
  setSort,
  expand,
  expandedHeader,
  clickOnRow,
}) => {
  const [expandedRow, setExpandedRow] = useState(null);
  const sortEdit = (item) => {
    setSort([item, `${sort[1] === "asc" ? "desc" : "asc"}`]);
  };
  const chbAll = () => {
    if (check.length === data.length) {
      setCheck([]);
    } else {
      setCheck(data.map((item) => item.values.id));
    }
  };

  const onClickExpand = (idx) => {
    if (!expand) return;

    if (expandedRow === idx) return setExpandedRow(null);
    setExpandedRow(idx);
  };

  return (
    <StyledTablePl className="settingtable">
      <table className={"records-table"}>
        <thead>
          <tr>
            {setCheck && (
              <td>
                <Checkbox
                  checked={check.length === data.length}
                  onChange={chbAll}
                  color="primary"
                />
              </td>
            )}

            {header.map((headerItem, headerIndex) => {
              return (
                <td
                  key={headerIndex}
                  onClick={() => sortEdit(headerItem.name)}
                  className={`left-content ${
                    sort[0] === headerItem.name ? " active" : ""
                  }`}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "2px",
                      justifyContent: "space-between",
                      width: "100",
                      flexWrap: "nowrap",
                    }}
                  >
                    <span>{headerItem.title}</span>
                    {!!headerItem.title && (
                      <ArrowDown
                        className={`sort-arrow${
                          sort[0] === headerItem.name ? " active" : ""
                        }${
                          sort[0] === headerItem.name && sort[1] === "desc"
                            ? " reverse"
                            : ""
                        }`}
                      />
                    )}
                  </div>
                </td>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {data.map((item, index, array) => {
            return (
              <>
                <TableRow
                  header={header}
                  item={item}
                  key={index}
                  check={check}
                  setCheck={setCheck}
                  expand={expand}
                  onClickExpand={onClickExpand}
                  index={index}
                  clickOnRow={clickOnRow}
                  expandedRow={expandedRow}
                />

                {expand &&
                  typeof expandedRow === "number" &&
                  expandedRow === index &&
                  data?.[expandedRow]?.values?.items?.map((item, index) => {
                    return (
                      <TableRow
                        header={expandedHeader}
                        item={{ values: item }}
                        key={index}
                        check={check}
                        setCheck={setCheck}
                        expand={expand}
                        clickOnRow={clickOnRow}
                        index={index}
                      />
                    );
                  })}
              </>
            );
          })}
        </tbody>
      </table>
      {data.length === 0 ? (
        <div
          style={{
            margin: "20px auto",
            textAlign: "center",
          }}
        >
          No data available in table
        </div>
      ) : null}
    </StyledTablePl>
  );
};

const StyledTablePl = styled.div`
  cursor: pointer;

  td.active {
    font-weight: 600;
  }

  .sort-arrow {
    opacity: 0.7;
    transition: all 0.3s ease-out;

    &.active {
      opacity: 1;
    }

    &.reverse {
      transform: rotate(180deg);
    }
  }

  table thead tr td.left-content {
    text-align: left;
  }
  table tbody tr td.left-content {
    text-align: left;
  }
`;

const TableRow = ({
  header,
  item,
  check,
  setCheck,
  expand,
  onClickExpand,
  index,
  clickOnRow,
  expandedRow,
}) => {
  const getCheck = () => {
    return check.findIndex((id) => id === item.values.id) !== -1;
  };

  const editCheck = () => {
    let temp = [...check];
    temp.splice(check.indexOf(item.values.id), 1);
    getCheck() ? setCheck(temp) : setCheck([...check, item.values.id]);
  };

  return (
    <Row
      isExpanded={expandedRow === index && expand}
      onClick={() => {
        if (onClickExpand) onClickExpand(index);

        if (clickOnRow) clickOnRow(item);
      }}
    >
      {setCheck && (
        <td>
          <Checkbox
            checked={getCheck()}
            onChange={() => editCheck()}
            color="primary"
          />
        </td>
      )}

      {header.map((headerItem, headerIndex) => {
        return (
          <>
            {onClickExpand && headerIndex === 0 && expand ? (
              <td key={headerIndex} className="left-content">
                <RenderMonthColumn
                  isActive={expandedRow === index}
                  value={item.values?.[headerItem.name]}
                />
              </td>
            ) : (
              <td key={headerIndex} className="left-content">
                {!!headerItem?.render
                  ? headerItem?.render(
                      item.values[headerItem.name],
                      headerItem.name,
                      item.values
                    )
                  : item.values?.[headerItem.name]}
              </td>
            )}
          </>
        );
      })}
    </Row>
  );
};

const Row = styled.tr`
  background-color: ${(props) => (props.isExpanded ? "#dee2e6" : "")};
  .profile-photo {
    height: 30px;
    width: 30px;
    object-fit: cover;
    border-radius: 15px;
    margin-right: 10px;
  }
`;
