import {
  TOGGLE_CHAT,
  SET_CHAT_TYPE_VIEW,
  SET_CURRENT_CHAT_ID,
  SET_CURRENT_CHATS_LIST,
  START_LOADING_CHATS,
  STOP_LOADING_CHATS,
  START_LOADING_MESSAGES,
  STOP_LOADING_MESSAGES,
  FETCH_MESSAGES,
  SET_CURRENT_MESSAGE_LIST,
  START_SENDING_MESSAGE,
  STOP_SENDING_MESSAGE,
  SEND_MESSAGE,
  SET_NEW_MESSAGE,
  SET_WS_MESSAGE,
  SET_VIEW_TYPE,
  UNPIN_MESSAGES,
  PIN_MESSAGES,
  PINNED_MESSAGES,
  OPEN_UPLOAD_MODAL,
  CLOSE_UPLOAD_MODAL,
  SET_FILES,
  REMOVE_FILE,
  GET_MORE_CHATS,
  GET_MODE_START,
  GET_MODE_END,
  SET_WS_MESSAGE_PINNED,
  SET_WS_MESSAGE_UNPINNED,
  SET_WS_MESSAGE_READ,
  SET_WS_MESSAGE_CHANGE_NAME,
  START_LIKE_MESSAGES,
  STOP_LIKE_MESSAGES,
  LIKE_MESSAGES,
  LIKE_MESSAGES_ACTION,
  START_DISLIKE_MESSAGES,
  STOP_DISLIKE_MESSAGES,
  DISLIKE_MESSAGES,
  DISLIKE_MESSAGES_ACTION,
  UPDATE_CHAT,
} from "../constants";
import {
  CHAT_VIEW_TYPE,
  VIEW_MESSAGING_TYPE,
} from "../../pages/home/Chats/constants";

const initialState = {
  chats: {
    currentChatId: null,
    chatList: [],
    currentPage: 1,
    chatsPerPage: 10,
    hasMoreChats: true,
    loading: false,
    error: null,
    fetching: false,
  },
  messages: {
    messagesList: {},
    currentPage: 1,
    messagesPerPage: 20,
    hasMoreMessages: true,
    loading: false,
    error: null,
    sending: false,
    fetching: false,
  },
  pinned: [],
  open: false,
  chatType: CHAT_VIEW_TYPE.ALL,
  view_type: VIEW_MESSAGING_TYPE.CHATS,
  modal: false,
  files: [],
};

export const chatReducer = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_CHAT:
      return {
        ...state,
        open: !state.open,
      };
    case OPEN_UPLOAD_MODAL:
      return {
        ...state,
        modal: true,
      };
    case CLOSE_UPLOAD_MODAL:
      return {
        ...state,
        modal: false,
      };

    case SET_VIEW_TYPE:
      return {
        ...state,
        view_type: action.payload,
      };
    case SET_FILES:
      return {
        ...state,
        files: action.payload,
      };

    case REMOVE_FILE:
      return {
        ...state,
        files: state.files.filter((item) => item.data.id !== action.payload),
      };

    case SET_CHAT_TYPE_VIEW:
      return {
        ...state,
        chatType: action.payload,
      };
    case SET_CURRENT_CHAT_ID:
      return {
        ...state,
        chats: {
          ...state.chats,
          currentChatId: action.payload,
        },
      };
    case SET_CURRENT_CHATS_LIST:
      return {
        ...state,
        chats: {
          ...state.chats,
          chatList: action.payload.data,
          meta: action.payload.meta,
        },
      };
    case UPDATE_CHAT:
      return {
        ...state,
        chats: {
          ...state.chats,
          chatList: state.chats.chatList.map((chat) => {
            if (chat.id === action.payload.chatRoomId) {
              return { ...chat, [action.payload.name]: action.payload.value };
            }
            return chat;
          }),
        },
      };
    case GET_MORE_CHATS:
      return {
        ...state,
        chats: {
          ...state.chats,
          chatList: [...state.chats.chatList, ...action.payload.data],
          meta: action.payload.meta,
        },
      };
    case SET_CURRENT_MESSAGE_LIST:
      return {
        ...state,
        messages: {
          ...state.messages,
          messagesList: {
            ...state.messages.messagesList,
            [action.payload.chatRoomId]: action.payload.messages,
          },
        },
      };
    case SET_NEW_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          messagesList: {
            ...state.messages.messagesList,
            [action.payload.chatRoomId]: [
              ...state.messages.messagesList[action.payload.chatRoomId],
              action.payload.message,
            ],
          },
        },
      };
    case SET_WS_MESSAGE:
      return {
        ...state,
        messages: {
          ...state.messages,
          messagesList: {
            ...state.messages?.messagesList,
            [action.payload.chat_room_id]: {
              ...(state?.messages?.messagesList?.[
                action.payload.chat_room_id
              ] || {}),
              messages: [
                ...(state?.messages?.messagesList?.[action.payload.chat_room_id]
                  ?.messages || {}),
                action.payload,
              ],
            },
          },
        },
      };
    case START_LOADING_CHATS:
      return { ...state, chats: { ...state.chats, loading: true } };
    case STOP_LOADING_CHATS:
      return { ...state, chats: { ...state.chats, loading: false } };
    case START_LOADING_MESSAGES:
      return { ...state, messages: { ...state.messages, loading: true } };
    case STOP_LOADING_MESSAGES:
      return { ...state, messages: { ...state.messages, loading: false } };
    case START_SENDING_MESSAGE:
      return { ...state, messages: { ...state.messages, sending: true } };
    case STOP_SENDING_MESSAGE:
      return { ...state, messages: { ...state.messages, sending: false } };

    case GET_MODE_START:
      return { ...state, chats: { ...state.chats, fetching: true } };
    case GET_MODE_END:
      return { ...state, chats: { ...state.chats, fetching: false } };

    case UNPIN_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          messagesList: {
            ...state.messages.messagesList,
            [action.payload.chat_id]: state?.messages?.messagesList?.[
              action.payload.chat_id
            ].map((item) =>
              action.payload.ids.includes(item.id)
                ? { ...item, is_pinned: false }
                : item
            ),
          },
        },
        pinned: state.pinned.filter(
          (item) => item.id !== action.payload.message.id
        ),
      };
    case PIN_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          messagesList: {
            ...state.messages.messagesList,
            [action.payload.chat_id]: state?.messages?.messagesList?.[
              action.payload.chat_id
            ].map((item) =>
              action.payload.ids.includes(item.id)
                ? { ...item, is_pinned: true }
                : item
            ),
          },
        },
        // pinned: [...state.pinned, action.payload.message],
      };

    case SET_WS_MESSAGE_UNPINNED:
      const { id, chat_room_id } = action.payload[0];

      return {
        ...state,
        messages: {
          ...state.messages,
          messagesList: {
            ...state.messages.messagesList,
            [chat_room_id]: state?.messages?.messagesList?.[
              chat_room_id
            ]?.map((item) =>
              id === item.id ? { ...item, is_pinned: false } : item
            ),
          },
        },
        pinned: state.pinned.filter((item) => item.id !== id),
      };
    // eslint-disable-next-line no-duplicate-case
    case SET_WS_MESSAGE_PINNED:
      const { id: messageId, chat_room_id: chatRoomId } = action.payload[0];

      return {
        ...state,
        messages: {
          ...state.messages,
          messagesList: {
            ...state.messages.messagesList,
            [chatRoomId]: state?.messages?.messagesList?.[
              chatRoomId
            ]?.map((item) =>
              messageId === item.id ? { ...item, is_pinned: true } : item
            ),
          },
        },
        pinned: [...state.pinned, action.payload[0]],
      };

    case SET_WS_MESSAGE_READ:
      const { chat_room_id: chatId } = action.payload[0];

      return {
        ...state,
        messages: {
          ...state.messages,
          messagesList: {
            ...state.messages.messagesList,
            [chatId]: action.payload,
          },
        },
      };

    case LIKE_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          messagesList: {
            ...state.messages.messagesList,
            [action.payload.chat_id]: state?.messages?.messagesList?.[
              action.payload.chat_id
            ]?.map((item) =>
              action.payload.messageId === item.id
                ? {
                    ...item,
                    liked_by: [...item?.liked_by, action.payload.meId],
                  }
                : item
            ),
          },
        },
      };
    case DISLIKE_MESSAGES:
      return {
        ...state,
        messages: {
          ...state.messages,
          messagesList: {
            ...state.messages.messagesList,
            [action.payload.chat_id]: state?.messages?.messagesList?.[
              action.payload.chat_id
            ]?.map((item) =>
              action.payload.messageId === item.id
                ? {
                    ...item,
                    liked_by: item?.liked_by?.filter(
                      (id) => id !== action.payload.meId
                    ),
                  }
                : item
            ),
          },
        },
      };
    case PINNED_MESSAGES:
      return { ...state, pinned: action.payload };
    default:
      return state;
  }
};
