import React, { useEffect, useRef } from "react";
import { ChatItem } from "./components/ChatItem/ChatItem";
import styled from "styled-components";
import { useDispatch } from "react-redux";
import {
  setChatIdAction,
  setViewTypeAction,
} from "../../../../../../../store/actions";
import { VIEW_MESSAGING_TYPE } from "../../../../constants";
import { Spinner } from "react-bootstrap";

export const ChatsList = ({ chats, fetching }) => {
  const dispatch = useDispatch();

  const handleClickChat = (chat) => {
    dispatch(setViewTypeAction(VIEW_MESSAGING_TYPE.MESSAGES));
    dispatch(setChatIdAction(chat.id));
  };

  if (!chats.length && !chats.loading) {
    return <EmptyBlock>Chats list is empty</EmptyBlock>;
  }

  return (
    <Wrapper>
      {chats.map((chat, idx) => (
        <ChatItem key={idx} chat={chat} onClick={() => handleClickChat(chat)} />
      ))}
      {fetching && (
        <SpinnerWrapper>
          <Spinner animation="border" role="status">
            <span className="sr-only">Loading...</span>
          </Spinner>
        </SpinnerWrapper>
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div``;

const EmptyBlock = styled.p`
  text-align: center;
  padding: 20px;
`;

const SpinnerWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0;
`;
