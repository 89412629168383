import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Modal } from "react-bootstrap";
import DropZone from "./DropZone";
import DatePicker from "../../../components/DatePicker";
import { TextField, Checkbox } from "@material-ui/core";
import { setFile } from "../../../crud/file.crud";
import Loader from "../../../components/Loader";
import moment from "moment";

export default ({ id, fetchData, loader, setLoader, tab }) => {
  const [modal, setModal] = useState(false);
  const [fileName, setFileName] = useState("");
  const [fileDate, setFileDate] = useState(null);
  const [files, setFiles] = useState([]);
  const [checked, setChecked] = useState(false);
  const [touched, setTouched] = useState({
    name: false,
    test_date: false,
  });
  const [errors, setErrors] = useState({});

  useEffect(() => {
    setFileName("");
    setFileDate(moment(new Date()).format("MM/DD/YYYY"));
    setFiles([]);
    setErrors({});
    setTouched({
      name: false,
      test_date: false,
    });
  }, [modal]);

  useEffect(() => {
    if (files.length) {
      setFileName(files[files.length - 1].data.file_name);
    }
  }, [files]);

  const handleChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleSave = () => {
    const is_progress = checked ? 1 : 0;

    const data = files.map((item) => {
      return {
        file: {
          file_data: {
            id: item.data.id,
            name: files.length <= 1 ? fileName : item.meta.name,
          },
          test_date: moment(fileDate, "MM/DD/YYYY").format("YYYY-MM-DD"),
          format: item.meta.type.split("/")[0],
        },
      };
    });
    setLoader(true);

    Promise.all(
      data.map((item) => {
        if (item.file.format === "image" && is_progress === 1) {
          return setFile(id, item.file, 1);
        } else {
          return setFile(id, item.file);
        }
      })
    )
      .then((res) => {
        setLoader(false);
        fetchData();
        setModal(false);
      })
      .catch(({ response }) => {
        setLoader(false);
        let touch = touched;

        Object.keys(touch).forEach((key) => (touch[key] = true));
        setTouched(touch);

        let errors = {};
        response.data.errors &&
          Object.keys(response.data.errors).forEach((item) => {
            const key = item.replaceAll("file_data", "").replaceAll(".", "");
            errors[key] = response.data.errors[item];
          });
        setErrors(errors);
      });
  };

  return (
    <UploadModalStyled>
      <Button
        vatiant="primary"
        className="btn-blue"
        onClick={() => setModal(true)}
        style={{ margin: "10px 0" }}
      >
        Upload
      </Button>
      <Modal show={modal} onHide={() => setModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Upload File</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Loader visible={loader} />
          <DropZone setData={setFiles} tab={tab} />
          <div style={{ marginTop: "8px" }}>
            Max file size:
            {tab == 0 && " 6MB"}
            {tab == 1 && " 50MB"}
            {tab == 2 && " 10MB"}
          </div>
          <div
            style={{
              position: "absolute",
              right: "20px",
              top: "235px",
            }}
          >
            Max number of files: 10
          </div>

          {files.length > 0 &&
            files.find((elem) => elem.meta.type.split("/")[0] === "image") && (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                <Checkbox
                  defaultChecked
                  color="primary"
                  inputProps={{ "aria-label": "secondary checkbox" }}
                  onChange={handleChange}
                  checked={checked}
                />
                <div style={{ color: "blue" }}>
                  Check if Uploading Progress Photo
                </div>
              </div>
            )}

          <DatePicker
            style={{ width: "100%" }}
            value={fileDate}
            label="Date"
            format={"MM/DD/YYYY"}
            onChange={(value) => {
              setFileDate(value, 0);
            }}
            onBlur={() => setTouched({ ...touched, test_date: true })}
            touchPicker={touched.test_date}
            helperText={touched.test_date && errors.test_date}
            error={Boolean(touched.test_date && errors.test_date)}
          />

          {files.length <= 1 && (
            <TextField
              variant="outlined"
              type="text"
              label="File Name"
              margin="normal"
              className="kt-width-full"
              name="file_name"
              inputProps={{
                maxLength: 300,
              }}
              onChange={(e) => setFileName(e.target.value)}
              value={fileName}
              helperText={touched.name && errors.name}
              error={Boolean(touched.name && errors.name)}
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Loader visible={loader} spinner={false} />
          <Button variant="secondary" onClick={() => setModal(false)}>
            Close
          </Button>
          <Button
            className="btn-blue btn btn-primary"
            onClick={handleSave}
            disabled={!files.length}
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </UploadModalStyled>
  );
};

const UploadModalStyled = styled.div``;
