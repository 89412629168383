import React, { useState } from "react";
import { ImgComponent } from "../../../../../../../Sales/helpers/ImgComponent";
import styled from "styled-components";
import { Avatar } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";
import { LikedMessage } from "../../../../../../../../../../assets";

const MultyAvatar = ({ user }) => {
  const [imageLoaded, setImageLoaded] = useState(true);

  const handleImageError = () => {
    setImageLoaded(false);
  };

  return (
    <MultyAvatarWraperInner>
      <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
        {user?.pic && imageLoaded ? (
          <ImgComponent
            src={user.pic}
            alt="img"
            className="like-by-photo"
            onError={handleImageError}
            withIndicator={true}
          />
        ) : (
          <Avatar className="like-by-photo">{`${user?.first_name[0]}${user?.last_name[0]}`}</Avatar>
        )}
      </Tooltip>
    </MultyAvatarWraperInner>
  );
};

export const LikeByBlock = ({ users, likedBy }) => {
  const [tooltipVisible, setTooltipVisible] = useState(false);

  const handleRemainingUsersClick = () => {
    setTooltipVisible(!tooltipVisible);
  };

  const likedUsers = users.filter((user) => likedBy.includes(user.id));
  return (
    <Wrapper>
      <Tooltip
        title={
          <div>
            {likedUsers.map((user, index) => (
              <p key={index} variant="body2">
                {`${user.first_name} ${user.last_name}`}
              </p>
            ))}
          </div>
        }
        open={tooltipVisible}
        onClose={() => setTooltipVisible(false)}
        onOpen={() => setTooltipVisible(true)}
      >
        <WrapperInner onClick={handleRemainingUsersClick}>
          <LikedMessage />
          {likedUsers?.map((user) => (
            <MultyAvatar user={user} key={user.id} />
          ))}
        </WrapperInner>
      </Tooltip>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  cursor: pointer;
  & .like-by-photo {
    width: 24px;
    height: 24px;
    margin-left: 6px;
  }
`;

const WrapperInner = styled.div`
  display: flex;
  align-items: center;
  gap: 2px;
  background: #0000000f;
  padding: 4px 8px 4px 8px;
  gap: 4px;
  border-radius: 20px;
  opacity: 0px;
`;

const MultyAvatarWraperInner = styled.div`
  &:not(:first-child) {
    margin-left: -4px;
  }
  & .read-by-photo {
    width: 24px;
    height: 24px;
  }
`;
