import React from "react";

export const ParseLinks = ({ text, isMy }) => {
  if (!text) return text;
  const urlRegex = /(https?:\/\/[^\s]+)/g;

  const parts = text.split(urlRegex);

  return parts.map((part, index) => {
    if (urlRegex.test(part)) {
      return (
        // eslint-disable-next-line react/react-in-jsx-scope
        <a
          style={{ color: isMy ? "white" : "#646c9a" }}
          href={part}
          key={index}
          target="_blank"
          rel="noopener noreferrer"
        >
          {part}
        </a>
      );
    }
    return part;
  });
};
