import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { CHAT_VIEW_TYPE_TITLE, CHAT_VIEW_TYPE } from "../../../../constants";
import {
  FormControl,
  InputAdornment,
  TextField,
  createStyles,
  makeStyles,
} from "@material-ui/core";
import { Search, Clear, MoreVert, CloseOutlined } from "@material-ui/icons";
import {
  SettingChatIcon,
  UnreadChatIcon,
  ArchivedChatIcon,
  FlaggedChatIcon,
  MessageIcon,
} from "../../../../../../../../assets";
import {
  setChatViewTypeAction,
  fetchChatsAction,
} from "../../../../../../../store/actions";
import useDebounce from "../../../../../../../hooks/useDebounce";
import { toggleChatAction } from "../../../../../../../store/actions";

const useStyles = makeStyles(() => {
  return createStyles({
    search: {
      margin: "0",
    },
  });
});

const CustomToggle = React.forwardRef(({ children, onClick, ...rest }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
    }}
    {...rest}
  >
    {children}
  </div>
));

const items = [
  {
    title: CHAT_VIEW_TYPE_TITLE[CHAT_VIEW_TYPE.ALL],
    value: CHAT_VIEW_TYPE.ALL,
    icon: MessageIcon,
  },

  {
    title: CHAT_VIEW_TYPE_TITLE[CHAT_VIEW_TYPE.ARCHIVED],
    value: CHAT_VIEW_TYPE.ARCHIVED,
    icon: ArchivedChatIcon,
  },

  {
    title: CHAT_VIEW_TYPE_TITLE[CHAT_VIEW_TYPE.UNREAD],
    value: CHAT_VIEW_TYPE.UNREAD,
    icon: UnreadChatIcon,
  },

  // {
  //   title: CHAT_VIEW_TYPE_TITLE[CHAT_VIEW_TYPE.SETTINGS],
  //   value: CHAT_VIEW_TYPE.SETTINGS,
  //   icon: SettingChatIcon,
  // },
];

const queryParams = {
  [CHAT_VIEW_TYPE.ALL]: { all: true },
  [CHAT_VIEW_TYPE.ARCHIVED]: { arhived: true },
  [CHAT_VIEW_TYPE.UNREAD]: { unread: true },
};

export const ChatsHeader = () => {
  const chat = useSelector((state) => state.chat);
  const [searchValue, setSearchValue] = useState("");

  const debouncedValue = useDebounce(searchValue);

  const dispatch = useDispatch();

  const { search } = useStyles();

  const [showClearIcon, setShowClearIcon] = useState("none");

  const handleChange = (event) => {
    setShowClearIcon(event.target.value === "" ? "none" : "flex");
    setSearchValue(event.target.value);
  };

  const handleClick = () => {
    setSearchValue("");
  };

  const handleClose = () => {
    dispatch(toggleChatAction(false));
  };

  const handleSelect = (type) => {
    dispatch(setChatViewTypeAction(+type));
    if (+type !== CHAT_VIEW_TYPE.SETTINGS) {
      dispatch(
        fetchChatsAction({
          ...queryParams[chat.chatType],
          search: debouncedValue,
        })
      );
    }
  };

  useEffect(() => {
    dispatch(
      fetchChatsAction({
        ...queryParams[chat.chatType],
        search: debouncedValue,
      })
    );
  }, [debouncedValue, chat.chatType]);

  return (
    <Wrapper>
      <Block>
        <Heading>
          <IconWrapper onClick={handleClose}>
            <CloseOutlined />
          </IconWrapper>
          <p>{CHAT_VIEW_TYPE_TITLE[chat.chatType]}</p>
        </Heading>
        <Settings>
          <Dropdown
            className="kt-header__topbar-item kt-header__topbar-item--user user-profile"
            drop="down"
            alignRight
            onSelect={handleSelect}
          >
            <Dropdown.Toggle
              as={CustomToggle}
              id="dropdown-toggle-chat-settings"
            >
              <ToggleWrapper>
                <MoreVert />
              </ToggleWrapper>
            </Dropdown.Toggle>

            <Dropdown.Menu
              className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
              style={{
                width: "260px",
                transform: "translate3d(-260px, 64px, 0px)",
                padding: "20px",
              }}
            >
              {items.map((item, idx) => {
                const Icon = item.icon;
                return (
                  <Dropdown.Item
                    as={"p"}
                    style={{ all: "unset", cursor: "pointer" }}
                    eventKey={item.value}
                    key={idx}
                  >
                    <DropdownItemWrapper>
                      <p>
                        <Icon width="14" height="14" />
                      </p>
                      <p>{item.title}</p>
                    </DropdownItemWrapper>
                  </Dropdown.Item>
                );
              })}
            </Dropdown.Menu>
          </Dropdown>
        </Settings>
      </Block>
      <Block>
        <FormControl style={{ width: "100%" }} className={search}>
          <TextField
            size="small"
            variant="outlined"
            onChange={handleChange}
            value={searchValue}
            placeholder="Search"
            style={{ width: "100%" }}
            fullWidth
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment
                  position="end"
                  style={{ display: showClearIcon, cursor: "pointer" }}
                  onClick={handleClick}
                >
                  <Clear />
                </InputAdornment>
              ),
            }}
          />
        </FormControl>{" "}
      </Block>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
  padding: 16px;
  width: 100%;
  border-bottom: 1px solid rgba(228, 231, 236, 1);
  & .dropdown-toggle::after {
    display: none;
  }
`;

const Block = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`;

const Heading = styled.p`
  font-size: 18px;
  font-weight: 500;
  line-height: 28px;
  text-align: center;
  margin: 0;
  display: flex;
  align-items: center;
  gap: 10px;
  & p {
    margin: 0;
  }
`;
const Settings = styled.div``;

const IconWrapper = styled.span`
  cursor: pointer;
  @media (min-width: 1024px) {
    display: none;
  }
`;

const ToggleWrapper = styled.div`
  border: 1px solid rgba(226, 226, 226, 1);
  border-radius: 8px;
  padding: 10px;
  cursor: pointer;
`;

const DropdownItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 6px;
`;
