import React from "react";
import { MoreVert } from "@material-ui/icons";
import styled from "styled-components";
import { Dropdown } from "react-bootstrap";
import {
  CHAT_VIEW_TYPE,
  VIEW_MESSAGING_TYPE,
} from "../../../../../../constants";
import {
  PinMessage,
  LikeMessage,
  CopyText,
} from "../../../../../../../../../../assets";
import {
  pinMessageAction,
  unpinMessageAction,
  likeMessageAction,
  dislikeMessageAction,
} from "../../../../../../../../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import * as AlertState from "../../../../../../../../../store/ducks/auth.duck";

const CustomToggle = React.forwardRef(({ children, onClick, ...rest }, ref) => (
  <div
    ref={ref}
    onClick={(e) => {
      e.stopPropagation();
      e.preventDefault();
      onClick(e);
    }}
    {...rest}
  >
    {children}
  </div>
));

export const MessageActions = ({
  messageId,
  isPinned,
  text,
  message,
  isLiked,
  isMy,
  meId,
}) => {
  const dispatch = useDispatch();
  const { chats } = useSelector((state) => state.chat);

  const items = [
    {
      title: isPinned ? "Unpin Message" : "Pin Message",
      value: isPinned
        ? CHAT_VIEW_TYPE.UNPIN_MESSAGE
        : CHAT_VIEW_TYPE.PIN_MESSAGE,
      icon: PinMessage,
    },
    {
      title: isLiked ? "Dislike Message" : "Like Message",
      value: isLiked
        ? CHAT_VIEW_TYPE.DISLIKE_MESSAGE
        : CHAT_VIEW_TYPE.LIKE_MESSAGE,
      icon: LikeMessage,
    },
    {
      title: "Copy Text",
      value: CHAT_VIEW_TYPE.COPY_TEXT,
      icon: CopyText,
    },
  ];

  const handleSelect = (type) => {
    if (+type === CHAT_VIEW_TYPE.PIN_MESSAGE) {
      dispatch(
        pinMessageAction({
          messages: [messageId],
          chatRoomId: chats.currentChatId,
          message,
        })
      );
    }
    if (+type === CHAT_VIEW_TYPE.UNPIN_MESSAGE) {
      dispatch(
        unpinMessageAction({
          messages: [messageId],
          chatRoomId: chats.currentChatId,
          message,
        })
      );
    }
    if (+type === CHAT_VIEW_TYPE.COPY_TEXT) {
      window.navigator.clipboard.writeText(text).then(() => {
        dispatch(
          AlertState.actions.alert({
            text: "Copied!",
            variant: true,
          })
        );
      });
    }
    if (+type === CHAT_VIEW_TYPE.LIKE_MESSAGE) {
      dispatch(
        likeMessageAction({
          chatRoomId: chats.currentChatId,
          messageId,
          meId,
        })
      );
    }
    if (+type === CHAT_VIEW_TYPE.DISLIKE_MESSAGE) {
      dispatch(
        dislikeMessageAction({
          chatRoomId: chats.currentChatId,
          messageId,
          meId,
        })
      );
    }
  };
  return (
    <Settings className="message-actions">
      <Dropdown
        className="kt-header__topbar-item kt-header__topbar-item--user user-profile"
        drop="down"
        alignRight
        onSelect={handleSelect}
      >
        <Dropdown.Toggle as={CustomToggle} id="dropdown-toggle-chat-settings">
          <ToggleWrapper>
            <MoreVert style={isMy || isPinned ? { color: "black" } : {}} />
          </ToggleWrapper>
        </Dropdown.Toggle>

        <Dropdown.Menu
          className="dropdown-menu-fit dropdown-menu-right dropdown-menu-anim dropdown-menu-top-unround dropdown-menu-xl"
          style={{
            width: "260px",
            transform: "translate3d(-260px, 64px, 0px)",
            padding: "20px",
          }}
        >
          {items.map((item, idx) => {
            const Icon = item.icon;
            return (
              <Dropdown.Item
                as={"p"}
                style={{ all: "unset", cursor: "pointer" }}
                eventKey={item.value}
                key={idx}
              >
                <DropdownItemWrapper>
                  <p>
                    <Icon width="10" height="10" />
                  </p>
                  <p>{item.title}</p>
                </DropdownItemWrapper>
              </Dropdown.Item>
            );
          })}
        </Dropdown.Menu>
      </Dropdown>
    </Settings>
  );
};

const Settings = styled.div`
  & .dropdown-toggle::after {
    display: none;
  }
`;

const ToggleWrapper = styled.div`
  cursor: pointer;
`;

const DropdownItemWrapper = styled.div`
  display: grid;
  grid-template-columns: 20px 1fr;
  gap: 6px;
`;
