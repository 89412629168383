import React from "react";
import styled from "styled-components";
import { Message } from "./components/Message/Message";
import { useSelector } from "react-redux";
import { CircularProgress } from "@material-ui/core";
import { PinnedMessages } from "./components/Message/components/PinnedMessages/PinnedMessages";

export const Messages = ({ messages, loading, chatRoomId }) => {
  const meId = useSelector((state) => state.auth.user.id);

  if (loading) {
    return (
      <LoadingBlock>
        <CircularProgress />;
      </LoadingBlock>
    );
  }

  if (!messages?.length) {
    return <EmptyBlock>Chats list is empty</EmptyBlock>;
  }

  return (
    <Wrapper>
      <PinnedMessages chatRoomId={chatRoomId} />
      {messages?.map((message, idx) => (
        <Message
          isMy={message.created_by.id === meId}
          key={idx}
          message={message}
        />
      ))}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  padding: 16px;
`;
const EmptyBlock = styled.p`
  text-align: center;
  padding: 20px;
`;

const LoadingBlock = styled.p`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
`;
