import moment from 'moment';
import * as AlertState from '../../../../store/ducks/auth.duck';
import catchErrorMessage from '../../../../helpers/errorCatcher';
import {
    createWorkout,
    editWorkout,
    changeProgramStatus,
    addToProgram,
    cloneWorkout,
    setWorkoutsProgram,
} from '../../../../crud/library.crud';
import {
    updateNote,
    deleteNotes,
    createNote,
} from '../../../../crud/notes.crud';

export function submitForm(
    values,
    completed_date,
    setLoader,
    sectionsAndExercises,
    originalExercises,
    profileId,
    isEditForLibrary,
    match,
    dispatch,
    completeWorkout,
    location,
    history,
    isCreateForLibrary,
    isCreateForProfile,
    isEditForProfileFromLibrary,
    isCloneFromLibrary,
    editAlreadyCloned,
    isCloneAlreadyCloned,
    programId
) {
    setLoader(true);

    const storeNotes = () => {
        const notes = [];

        const exercisesArr = sectionsAndExercises.filter(elem => elem.type === 'exercise')
        exercisesArr.forEach((exercise) => {
            const originalExercise = originalExercises.find(elem => elem.id === exercise.id)
            if (!originalExercise && !exercise.note) return
            if (
                (!originalExercise && exercise.note) || // add note
                (!originalExercise.note && exercise.note) || // add note
                (originalExercise.note?.note_text && exercise.note?.note_text && originalExercise.note.note_text !== exercise.note?.note_text) || // update note
                (exercise.note?.note_text && typeof exercise.note.note_text === 'string' && exercise.note.note_text.trim() === '') // delete note
            ) {
                notes.push({
                    id: originalExercise ? originalExercise.note?.id : null,
                    exercise_id: exercise.id,
                    note_text: exercise.note.note_text,
                    note_date: moment().format('YYYY-MM-DD'),
                })
            }
        });

        notes.forEach(noteData => {
            if (!noteData.note_text) return deleteNotes(profileId, [noteData.id]); // delete note
            if (noteData.id) return updateNote(profileId, noteData); // update note
            createNote(profileId, noteData); // create note
        });
    };

    let sectionsAndExercisesArr = [...sectionsAndExercises];

    sectionsAndExercisesArr.forEach((elem, index) => {
        if (elem.id && elem.id.indexOf('drag') !== -1) {
            delete sectionsAndExercisesArr[index].id;
        }

        delete sectionsAndExercisesArr[index].exerciseError;
    });

    const sectionsAndExercisesWithOrder =
        sectionsAndExercises
            .filter(elem => {
                if(
                    elem.type === 'section' &&
                    !elem.title
                ) {
                    return false
                } else return true
            })
            .filter(elem => {
                if(
                    elem.type === 'exercise' &&
                    (!elem.sets || !elem.sets.length)
                ) {
                    return false
                } else return true
            })
            .map((elem, index) => ({ ...elem, order: index + 1 }));

    const exercisesWithoutSections = [];
    let stop = false
    let firstSectionIndex = -1;
    sectionsAndExercisesWithOrder.forEach((elem, index) => {
        if (stop === true) return;
        if (elem.type === 'exercise') {
            exercisesWithoutSections.push(elem)
        }

        if (elem.type === 'section') {
            stop = true;
            firstSectionIndex = index;
        }
    });

    const sectionsWithExercises = [];
    let sectionOrder = 0;
    sectionsAndExercisesWithOrder.forEach((elem, index) => {
        if (index < firstSectionIndex || firstSectionIndex === -1) return;

        if (elem.type === 'section') {
            sectionsWithExercises.push({
                title: elem.title,
                order: elem.order,
                id: +elem.id,
                exercises: [],
            })
            sectionOrder = elem.order;
        }

        if (elem.type === 'exercise' && elem.sets && elem.sets.length) {
            sectionsWithExercises
                .find(elem => elem.order === sectionOrder)
                .exercises
                .push({
                    ...elem,
                    id: +elem.id,
                    order: elem.order,
                    sets: elem.sets.map(set => ({
                        ...set,
                        reps: +set.reps || 0,
                        weight: +set.weight || 0,
                        distance: +set.distance || 0
                    }))
                })
        }
    });

    const data = {
        ...values,
        exercises: exercisesWithoutSections.map(elem => ({
            ...elem,
            id: +elem.id,
            order: elem.order,
            sets: elem.sets.map(set => ({
                ...set,
                reps: +set.reps || 0,
                weight: +set.weight || 0,
                distance: +set.distance || 0
            }))
        })),
        sections: sectionsWithExercises,
    };

    if (profileId) {
        // clone/create/edit workout for profile
        data.cloner_id = profileId;
    } else {
        // create/edit workout for library
        data.cloner_id = null;
    }

    const editWorkoutCallback = () => {
        dispatch(AlertState.actions.alert({
            text: 'Workout saved successfully',
            variant: true
        }));

        if (editAlreadyCloned) storeNotes();

        // complete workout
        if (completeWorkout) {
            changeProgramStatus(profileId, {
                workout_ids: [location.state?.assignmentId],
                is_completed: true
            })
                .then(() => {
                    dispatch(AlertState.actions.alert({
                        text: 'Workout completed successfully',
                        variant: true
                    }));
                    history.push(`/profile/${profileId}/program`)
                })
                .catch(err => {
                    let errorMessage = catchErrorMessage(err) || 'Complete workout error';
                    dispatch(AlertState.actions.alert({
                        text: errorMessage,
                        variant: false
                    }))
                })
        } else {
            match?.params?.profileId
                ? history.push(`/profile/${profileId}/program`)
                : history.push('/library/workouts/list')
        }
    }

    // edit workout for profile
    if (isEditForLibrary || editAlreadyCloned) {
        if (programId) {
            setWorkoutsProgram(profileId, programId, { ...data, completed_date })
                .then(() => editWorkoutCallback())
                .catch(err => {
                    let errorMessage = catchErrorMessage(err) || 'Edit workout error';
                    dispatch(AlertState.actions.alert({
                        text: errorMessage,
                        variant: false
                    }))
                })
                .finally(() => {
                    setLoader(false);
                    completeWorkout = false;
                })
        } else {
            editWorkout(match.params.id, data)
                .then(() => editWorkoutCallback())
                .catch(err => {
                    let errorMessage = catchErrorMessage(err) || 'Edit workout error';
                    dispatch(AlertState.actions.alert({
                        text: errorMessage,
                        variant: false
                    }))
                })
                .finally(() => {
                    setLoader(false);
                    completeWorkout = false;
                })
        }
        return;
    };

    if (isCloneAlreadyCloned || isEditForProfileFromLibrary || isCloneFromLibrary) {
        let complete = 0;
        if (completeWorkout) complete = 1;
        cloneWorkout(profileId, location.state?.assignmentId, complete, data, isEditForProfileFromLibrary)
            .then(() => {
                dispatch(AlertState.actions.alert({
                    text: 'Workout duplicated for profile successfully',
                    variant: true
                }));
                history.push(`/profile/${profileId}/program`);
                storeNotes();
            })
            .catch((err) => {
                let errText = catchErrorMessage(err);
                dispatch(AlertState.actions.alert({
                    text: errText,
                    variant: false,
                }));
            })
            .finally(() => {
                setLoader(false);
            })
        return;
    };

    // create/clone workout for library/profile
    if (isCreateForLibrary || isCreateForProfile) {
        createWorkout(data)
            .then((res) => {
                // assign to profile
                if (isCreateForProfile) {
                    storeNotes();

                    const data = {
                        is_unattach_enabled: false,
                        programs: [{
                            id: null,
                            completed_date,
                            workouts: [{ id: res.data.data.id }],
                        }]
                    }

                    addToProgram(profileId, data)
                        .then(() => {
                            dispatch(AlertState.actions.alert({
                                text: 'Workouts added successfully',
                                variant: true,
                            }));
                            history.push(`/profile/${profileId}/program`);
                        })
                        .catch(err => {
                            let errText = catchErrorMessage(err);
                            dispatch(AlertState.actions.alert({
                                text: errText,
                                variant: false,
                            }));
                        })
                        .finally(() => setLoader(false));
                } else {
                    match?.params?.profileId
                        ? history.push(`/profile/${profileId}/program`)
                        : history.push('/library/workouts/list')
                }
            })
            .catch(err => {
                let errorMessage = catchErrorMessage(err) || 'Create workout error';
                dispatch(AlertState.actions.alert({
                    text: errorMessage,
                    variant: false
                }))
            })
            .finally(() => {
                setLoader(false);
            })
    };
};
