import React from "react";
import styled from "styled-components";

export const ReadColumn = ({ value }) => {
  return <Wrapper isActive={!value}></Wrapper>;
};

const Wrapper = styled.div`
  width: 14px;
  height: 14px;
  opacity: 0px;
  border: 1px solid rgba(230, 232, 240, 1);
  border-radius: 50%;
  background-color: ${(props) =>
    props.isActive ? "rgba(242, 114, 50, 1)" : "transparent"};
`;
