export const CHAT_VIEW_TYPE = {
  ALL: 1,
  UNREAD: 2,
  ARCHIVED: 3,
  FLAGGED: 4,
  SETTINGS: 5,
  HIDE_CHAT: 6,
  LEAVE_CHAT: 7,
  PIN_MESSAGE: 8,
  LIKE_MESSAGE: 9,
  DISLIKE_MESSAGE: 12,
  COPY_TEXT: 10,
  UNPIN_MESSAGE: 11,
};

export const CHAT_VIEW_TYPE_TITLE = {
  [CHAT_VIEW_TYPE.ALL]: "All Chats",
  [CHAT_VIEW_TYPE.UNREAD]: "Unread Chats",
  [CHAT_VIEW_TYPE.ARCHIVED]: "Archived Chats",
  [CHAT_VIEW_TYPE.SETTINGS]: "Chat Settings",
  [CHAT_VIEW_TYPE.HIDE_CHAT]: "Hide Chat",
  [CHAT_VIEW_TYPE.LEAVE_CHAT]: "Leave Chat",
};

export const VIEW_MESSAGING_TYPE = {
  CHATS: 1,
  MESSAGES: 2,
  SETTINGS: 3,
};
