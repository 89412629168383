import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Button, Modal } from "react-bootstrap";
import * as media from "../../../../../crud/media.crud";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import moment from "moment";

const ImgComponent = ({ src, alt, className }) => {
  const [url, setUrl] = useState("");

  useEffect(() => {
    src &&
      media
        .getImg(src)
        .then((res) => setUrl(res))
        .catch((err) => setUrl(err));
  }, [src]);
  return url && <img src={url} alt={alt} className={className} />;
};

export default ({
  modal,
  setModal,
  item,
  activePreview,
  setActivePreview,
  data,
  setCurrentItem,
}) => {
  const prevPreview = () => {
    const currentIndex = data.findIndex((item) => item.id === activePreview);
    if (currentIndex > 0) {
      setModal(false);
      setCurrentItem(data[currentIndex - 1]);
      setActivePreview(data[currentIndex - 1].id);
      setTimeout(() => setModal(true), 200);
    }
  };

  const nextPreview = () => {
    const currentIndex = data.findIndex((item) => item.id === activePreview);
    if (currentIndex < data.length - 1) {
      setModal(false);
      setCurrentItem(data[currentIndex + 1]);
      setActivePreview(data[currentIndex + 1].id);
      setTimeout(() => setModal(true), 200);
    }
  };

  const activePreviewIndex = () => {
    return data.findIndex((item) => item?.id === activePreview);
  };

  return (
    <div>
      <Modal
        size="lg"
        show={modal}
        onHide={() => {
          setModal(false);
          setActivePreview(0);
        }}
      >
        <Modal.Header closeButton>
          <Modal.Title>Preview Photo</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PreviewModalStyled className="photomodal">
            {activePreviewIndex() > 0 && (
              <ChevronLeftIcon
                className="photomodal-left"
                onClick={prevPreview}
              />
            )}
            {activePreviewIndex() < data.length - 1 && (
              <ChevronRightIcon
                className="photomodal-right"
                onClick={nextPreview}
              />
            )}
            <div className="photomodal-priview">
              <ImgComponent
                src={item?.file?.path}
                alt="images"
                className={"photomodal-priview"}
              />
            </div>
            <div>
              <p className="photomodal-filename">{item?.file?.file_name}</p>
              <p className="photomodal-testdate">
                {item?.test_date &&
                  moment(item?.test_date, "YYYY-MM-DD").format("MM/DD/YYYY")}
              </p>
            </div>
          </PreviewModalStyled>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => {
              setModal(false);
              setActivePreview(0);
            }}
          >
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

const PreviewModalStyled = styled.div`
  .photomodal {
    display: flex;
    flex-direction: column;
    position: relative;

    &-left {
      position: absolute;
      top: 50%;
      left: 16px;
      transform: translateY(-50%);
      height: 40px;
      width: 40px;
      border-radius: 20px;
      background: #646c9a;

      path {
        fill: #ffffff;
      }
    }

    &-right {
      position: absolute;
      top: 50%;
      right: 16px;
      transform: translateY(-50%);
      height: 40px;
      width: 40px;
      border-radius: 20px;
      background: #646c9a;

      path {
        fill: #ffffff;
      }
    }

    &-name {
      font-size: 15px;
      margin: 15px 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        width: 30%;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }

    &-priview {
      width: 100%;
      height: 300px;
      min-height: 300px;
      max-height: 300px;
      object-fit: contain;
      padding: 0 20px;
    }

    &-filename {
      text-align: center;
      font-size: 15px;
      font-weight: 500;
      margin: 20px 0 0;
    }

    &-testdate {
      text-align: center;
      font-size: 15px;
      margin: 10px 0 0;
    }
  }
`;
