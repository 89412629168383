import React from "react";
import { FlaggedIcon } from "../../../../../../../assets";
import { Close } from "@material-ui/icons";
import styled from "styled-components";

export const FlaggedColumn = ({ value }) => {
  return <Wrapper>{!!value ? <FlaggedIcon /> : <Close />}</Wrapper>;
};

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: start;
`;
