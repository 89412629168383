import React from "react";
import { CheckIcon } from "../../../../../../../assets";
import { Close } from "@material-ui/icons";
import styled from "styled-components";

export const AttachmentsColumn = ({ value }) => {
  return <Wrapper>{!!value ? <CheckIcon /> : <Close />}</Wrapper>;
};

const Wrapper = styled.div``;
