import React from "react";

import { Box, Tab, Tabs } from "@material-ui/core";
import { Files } from "./components/Files/Files";
import styled from "styled-components";
import { ArchivedChatIcon, LogoutIcon } from "../../../../../../assets";
import { ChatAvatars } from "../Chats/components/ChatsList/components/ChatAvatars/ChatAvatars";
import { Member } from "./components/Member/Member";
import { archiveChatAction } from "../../../../../store/actions";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      style={{ color: "#a2a3b5" }}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export const ChatSettings = ({ chatInfo, currentChatId, loading }) => {
  const dispatch = useDispatch();
  const { participants, name } = useSelector((state) =>
    state.chat.chats.chatList.find((item) => item.id === currentChatId)
  );
  const [value, setValue] = React.useState(0);

  const [archived, setArchived] = React.useState(false);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleArchiveChat = () => {
    dispatch(
      archiveChatAction({
        chatRoomId: currentChatId,
        body: { archived: !archived },
      })
    );
    setArchived(!archived);
  };

  return (
    <Wrapper>
      <UsersWrapper>
        <ChatAvatars users={participants} />
      </UsersWrapper>
      <ChatName>{name}</ChatName>
      <IdWrapper>
        <p>{`Chat ID #${currentChatId}`}</p>
      </IdWrapper>
      <ButtonWrapper>
        <CustomButton onClick={handleArchiveChat}>
          {archived ? "Archived" : "Archive"} <ArchivedChatIcon />
        </CustomButton>
        <CustomButton>
          Leave <LogoutIcon />{" "}
        </CustomButton>
      </ButtonWrapper>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          variant="fullWidth"
          aria-label="full width tabs example"
          className="settings-tabs"
        >
          <Tab label="Members" {...a11yProps(0)} />
          <Tab label="Media" {...a11yProps(1)} />
          <Tab label="Docs" {...a11yProps(2)} />
          <Tab label="Links" {...a11yProps(3)} />
        </Tabs>
      </Box>
      <CustomTabPanel value={value} index={0}>
        <Member chatRoomId={currentChatId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={1}>
        <Files value={value} chatRoomId={currentChatId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={2}>
        <Files value={value} chatRoomId={currentChatId} />
      </CustomTabPanel>
      <CustomTabPanel value={value} index={3}>
        <Files value={value} chatRoomId={currentChatId} />
      </CustomTabPanel>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & .MuiTabs-flexContainer {
    display: inline-grid;
    grid-template-columns: 25% 25% 25% 25%;
    width: 100%;
  }
  & .MuiTabs-indicator {
    background-color: #a2a3b5;
  }
`;

const IdWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  font-family: "Inter";
  font-size: 12px;
  font-weight: 400;
  line-height: 14px;
  text-align: center;
  color: grey;
`;

const UsersWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  padding-top: 20px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  justify-content: center;
  margin-bottom: 24px;
`;

const CustomButton = styled.div`
  width: 99px;
  height: 46px;
  gap: 4px;
  border-radius: 8px;
  opacity: 0px;
  border: 1px solid #a2a3b5;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
`;

const ChatName = styled.div`
  font-family: "Inter";
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
  text-align: center;
  margin-bottom: 10px;
`;
