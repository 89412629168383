/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/pages/auth/AuthPage`, `src/pages/home/HomePage`).
 */

import React, { useEffect, useState } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import { useLastLocation } from "react-router-last-location";
import HomePage from "../pages/home/HomePage";
import ErrorsPage from "../pages/errors/ErrorsPage";
import RefreshPassword from "../pages/auth/RefreshPassword";
import LogoutPage from "../pages/auth/Logout";
import { LayoutContextProvider } from "../../_metronic";
import Layout from "../../_metronic/layout/Layout";
import * as routerHelpers from "../router/RouterHelpers";
import AuthPage from "../pages/auth/AuthPage";
import ReportPDF from "../pages/PDF/ReportPDF";
import { FormPDF } from "../pages/PDF/FormPDF";
import { ExercisePdf } from "../pages/PDF/ExercisePdf";
import { EventPdf } from "../pages/PDF/EventPdf";
import { WorkoutPdf } from "../pages/PDF/WorkoutPdf";
import { ProfileWorkoutPdf } from "../pages/PDF/ProfileWorkoutPdf";
import { ProgramPdf } from "../pages/PDF/ProgramPdf";
import { getUserByToken, getAccountByToken } from "../../app/crud/auth.crud";
import { getCurrentSubscription } from "../../app/crud/biling.crud";
import { useDispatch } from "react-redux";
import * as AlertState from "../../app/store/ducks/auth.duck";
import Intercom from "intercom-react";
import Drift from "react-driftjs";
import Profitwell from "profitwell-component";
import { fetchCRMList } from "../../app/crud/crm.crud";
import { setCRMAction, setPlanAction } from "../../app/store/actions";
import * as Sentry from "@sentry/react";

const Routes = withRouter((props) => {
  const { history } = props;
  const accountId = useSelector((state) => state.appData?.account?.id);
  const lastLocation = useLastLocation();
  routerHelpers.saveLastLocation(lastLocation);
  // eslint-disable-next-line
  const { isAuthorized, userEmail, menuConfig, userLastLocation } = useSelector(
    ({ auth, urls, builder: { menuConfig } }) => {
      return {
        menuConfig,
        isAuthorized: auth.user != null,
        userEmail: auth.user?.email,
        userLastLocation: routerHelpers.getLastLocation(),
      };
    },
    shallowEqual
  );

  const auth = useSelector((state) => state?.auth?.user);

  const [intercom, setIntercom] = useState(false);
  const [drift, setDrift] = useState(false);
  const [userId, setUserId] = useState();

  useEffect(() => {
    process.env.REACT_APP_INTERCOM_CHAT !== "hidden" &&
      setTimeout(() => {
        const elem = document.querySelector('iframe[title="intercom"] ');
        if (elem) {
          elem.style.right = "0";
        }
      }, 6000);
  }, []);

  const dispatch = useDispatch();

  useEffect(() => {
    getAccountByToken().then((res) => {
      setUserId(res.data.data.service_customer_id);
    });
    getUserByToken()
      .then((res) => {
        dispatch(AlertState.actions.fulfillUser(res.data));
        setIntercom({
          email: res.data.data.email,
          created_at: res.data.data.emailVerifiedAt,
          name: `${res.data.data.firstName} ${res.data.data.lastName}`,
        });
        setDrift({
          email: res.data.data.email,
          name: `${res.data.data.firstName} ${res.data.data.lastName}`,
          first_name: res.data.data.firstName,
          last_name: res.data.data.lastName,
          company: res.data.data.company_name,
        });
        getCurrentSubscription()
          .then((res2) => {
            if (res2.data.data && res2.data.data.plan) {
              window.Appcues.identify(res.data.data.id, {
                name: `${res.data.data.firstName} ${res.data.data.lastName}`,
                email: res.data.data.email,
                created_at: res.data.data.emailVerifiedAt,
                company_name: res.data.data.company_name,
                plan: res2.data.data.plan.name,
                plan_tier: res2.data.data.plan.id,
                role: res.data.data.roles,
              });
            }
            dispatch(setPlanAction(res2.data.data));
            return res2;
          })
          .catch(() => {
            setDrift(false);
            setIntercom(false);
          });
      })
      .catch(() => {
        setDrift(false);
        setIntercom(false);
      });

    // Appcues
    if (window.Appcues) window.Appcues.page();
    // End Appcues
  }, [props.location.pathname]); // eslint-disable-line

  useEffect(() => {
    if (accountId) {
      fetchCRMList(accountId).then((res) => {
        if (res?.data?.list) {
          dispatch(setCRMAction(res.data.list));
        }
      });
    }
  }, [accountId]);

  useEffect(() => {
    const dsn =
      "https://9de60be00fa3c2be82497bc207480d47@o4507577035849728.ingest.de.sentry.io/4507577038798928";

    Sentry.init({
      release: "Trainer Metrix",
      dsn,
      integrations: [
        Sentry.browserTracingIntegration(),
        Sentry.replayIntegration(),
      ],
      replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
      ignoreErrors: [
        "Error: Network Error",
        "Error: ResizeObserver loop limit exceeded",
        "NetworkError",
        "ResizeObserver loop limit exceeded",
        "Non-Error promise rejection captured with keys",
      ],
      beforeBreadcrumb(breadcrumb, hint) {
        if (breadcrumb.category === "console") {
          return null;
        }
        return breadcrumb;
      },

      // We recommend adjusting this value in production, or using tracesSampler
      // for finer control
      tracesSampleRate: 1.0,
    });
  }, []);

  if (auth?.id) {
    Sentry.setUser({
      username: `${auth.firstName} ${auth.lastName}`,
      id: auth?.id,
      company_name: auth?.company_name,
      email: auth.email,
    });
  }

  return (
    /* Create `LayoutContext` from current `history` and `menuConfig`. */
    <LayoutContextProvider history={history} menuConfig={menuConfig}>
      {/* Drift */}
      {drift && userId && process.env.REACT_APP_DRIFT_CHAT !== "disable" && (
        <Drift
          appId="rzyz5e5by27m"
          userId={"USER-" + userEmail}
          attributes={drift}
          style={{
            bottom: "10px",
          }}
        />
      )}
      {/* End Drift */}

      {/* Intercom */}
      {intercom && process.env.REACT_APP_INTERCOM_CHAT !== "disable" && (
        <Intercom appId="hetr4i01" user={intercom} />
      )}
      {/* End Intercom */}

      {/*Profitwell */}
      {!!userId && (
        <Profitwell
          authToken="61437c71cb6da32d50cdf0c07719cdb7"
          email={`${userId}`}
        />
      )}
      {/* End Profitwell */}

      <Switch>
        <Route path="/refresh-password/:id" component={RefreshPassword} />
        {!isAuthorized ? (
          /* Render auth page when user at `/auth` and not authorized. */
          <AuthPage />
        ) : (
          /* Otherwise redirect to root page (`/`) */
          <Redirect from="/auth" to="/profile-list" />
        )}

        <Route path="/error" component={ErrorsPage} />
        <Route path="/logout" component={LogoutPage} />

        {!isAuthorized ? null : (
          <Route path="/report/:id/:type/:data" component={ReportPDF} />
        )}

        {!isAuthorized ? null : <Route path="/form/:id" component={FormPDF} />}

        {!isAuthorized ? null : (
          <Route path="/exercise/:id" component={ExercisePdf} />
        )}

        {!isAuthorized ? null : (
          <Route path="/events/:id" component={EventPdf} />
        )}

        {!isAuthorized ? null : (
          <Route path="/workout/:id" component={WorkoutPdf} />
        )}

        {!isAuthorized ? null : (
          <Route
            path="/profile/:profileId/workout/:id/pdf"
            component={ProfileWorkoutPdf}
          />
        )}

        {!isAuthorized ? null : (
          <Route path="/program/:id" component={ProgramPdf} />
        )}

        {!isAuthorized ? (
          /* Redirect to `/auth` when user is not authorized */
          <Redirect to="/auth/login" />
        ) : (
          <Layout>
            <HomePage />
          </Layout>
        )}
      </Switch>
    </LayoutContextProvider>
  );
});

export default Routes;
