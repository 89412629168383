import React from "react";
import styled from "styled-components";
import { ImgComponent } from "../../../../../../../Sales/helpers/ImgComponent";
import { Avatar } from "@material-ui/core";
import { Tooltip } from "@material-ui/core";

const SingleAvatar = ({ users }) => {
  return (
    <SingleAvatarWraper>
      {users?.[0]?.pic ? (
        <ImgComponent
          withIndicator={true}
          src={users[0].pic}
          alt="img"
          className="profile-photo"
        />
      ) : (
        <Avatar className="profile-photo">{`${users[0]?.first_name[0]}${users[0]?.last_name[0]}`}</Avatar>
      )}
    </SingleAvatarWraper>
  );
};

const MultyAvatar = ({ users }) => {
  return (
    <MultyAvatarWraper>
      {users?.map((user, idx) => (
        <MultyAvatarWraperInner key={idx}>
          <Tooltip title={`${user?.first_name} ${user?.last_name}`}>
            {user?.pic ? (
              <p style={{ width: "40px", margin: "0" }}>
                <ImgComponent
                  src={user.pic}
                  alt="img"
                  className="profile-photo"
                  withIndicator={true}
                />
              </p>
            ) : (
              <Avatar className="profile-photo">{`${user?.first_name[0]}${user?.last_name[0]}`}</Avatar>
            )}
          </Tooltip>
        </MultyAvatarWraperInner>
      ))}
    </MultyAvatarWraper>
  );
};

export const ChatAvatars = ({ users }) => {
  if (!users) return "Profile";
  return (
    <Wrapper>
      {users?.length > 1 ? (
        <MultyAvatar users={users} />
      ) : (
        <SingleAvatar users={users} />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  & .profile-photo {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
`;
const SingleAvatarWraper = styled.div``;
const MultyAvatarWraper = styled.div`
  display: flex;
  align-items: center;
`;
const MultyAvatarWraperInner = styled.div`
  &:not(:first-child) {
    margin-left: -20px;
  }
`;
